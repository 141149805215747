import { Box } from '@mui/material';
import NavigationBar from './NavigationBar';
import BarionPixel from 'components/BarionPixel/BarionPixel';

const Layout = ({ children }) => {
  return (
    <>
      <BarionPixel />
      <Box
        sx={{
          height: '100dvh',
          width: '100vw',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: { xs: 'column-reverse', md: 'row' }
        }}
      >
        <NavigationBar />
        <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden' }}>{children}</Box>
      </Box>
    </>
  );
};

export default Layout;
