import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import useOptions from '../Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';

const useTransactionsFilters = () => {
  const { transactionStatuses } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'status',
      label: 'Tr. Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: transactionStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useTransactionsFilters;
