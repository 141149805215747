import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog
} from '@mui/material';

const Dialog = ({
  isOpen,
  onClose,
  onConfirm,
  cancelText,
  acceptText,
  text,
  title,
  content,
  children,
  hideActions,
  confirmDisabled
}) => {
  return (
    <MuiDialog
      open={!!isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>}
        {content && content}
        {children && children}
      </DialogContent>
      {!hideActions && (
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            {cancelText}
          </Button>
          <Button onClick={onConfirm} color='primary' variant='filled' disabled={confirmDisabled} autoFocus>
            {acceptText}
          </Button>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  cancelText: 'Mégse',
  acceptText: 'Ok',
  title: '',
  text: ''
};

Dialog.propTypes = {
  isOpen: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  acceptText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  hideActions: PropTypes.bool
};

export default Dialog;
