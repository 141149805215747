import TablePage from 'components/Common/TablePage';
import useStudentCoursesFields from './useStudentCoursesFields';
import useStudentCoursesApi from './useStudentCoursesApi';

const StudentCoursesList = ({ studentId }) => {
  const { getStudentCourses, updateStudentCourse } = useStudentCoursesApi(studentId);
  const fields = useStudentCoursesFields();

  const tableProps = {
    title: 'Tanfolyam súlyozás',
    titleVariant: 'body1',
    titleGutterBottom: 1,
    fields,
    idField: 'uid',
    onGetRows: getStudentCourses,
    onUpdateRow: updateStudentCourse
  };

  return <TablePage {...tableProps} />;
};

export default StudentCoursesList;
