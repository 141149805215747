import * as yup from 'yup';

const usePaymentProfileFields = () => {
  return [
    {
      name: 'name',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Név'
      },
      rule: yup.string().required()
    },
    {
      name: 'zipCode',
      type: 'text',
      fieldProps: {
        label: 'Irányítószám'
      },
      rule: yup.string().required()
    },
    {
      name: 'city',
      type: 'text',
      fieldProps: {
        label: 'Város'
      },
      rule: yup.string().required()
    },
    {
      name: 'address',
      type: 'text',
      fieldProps: {
        label: 'Cím'
      },
      rule: yup.string().required()
    },
    {
      name: 'vatNumber',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Adószam'
      },
      rule: yup.string().required()
    },
    {
      name: 'accountNumber',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Számlaszám'
      },
      rule: yup.string().required()
    },
    {
      name: 'barionPosKey',
      type: 'text',
      fieldProps: {
        label: 'Barion Pos Key'
      },
      rule: yup.string().required()
    },
    {
      name: 'barionPixel',
      type: 'text',
      fieldProps: {
        label: 'Barion Pixel Id'
      },
      rule: yup.string().required()
    },
    {
      name: 'barionEnvironment',
      type: 'select',
      showInTable: true,
      fieldProps: {
        label: 'Barion környezet',
        options: [
          { key: 'Test', value: 'Test' },
          { key: 'Live', value: 'Live' }
        ]
      },
      rule: yup.string().required()
    },
    {
      name: 'barionEmail',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Barion email'
      },
      rule: yup.string().email().required()
    },
    {
      name: 'apiAgentKey',
      type: 'text',
      fieldProps: {
        label: 'Számlázz.hu API kulcs'
      },
      rule: yup.string().required()
    }
  ];
};

export default usePaymentProfileFields;
