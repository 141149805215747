import React, { useState } from 'react';
import { CardContent, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import subscriptionTypeFormFields from './subscriptionTypeFormFields';
import { Form } from '../../ui';
import useSubscriptionApi from './useSubscriptionApi';
import { DeleteRounded } from '@mui/icons-material';
import List from '@mui/material/List';

const SubscriptionListAdmin = ({ courseId, subscriptionTypes, refetch }) => {
  const [loading, setLoading] = useState(false);
  const { createSubscriptionTypes, deleteSubscriptionTypes } = useSubscriptionApi();

  const handleSubmit = async (fields) => {
    setLoading(true);
    await createSubscriptionTypes(courseId, fields);
    refetch();
    setLoading(false);
  };

  const handleDelete = async (uid) => {
    setLoading(true);
    await deleteSubscriptionTypes(courseId, uid);
    refetch();
    setLoading(false);
  };

  return (
    <>
      <CardContent>
        {subscriptionTypes.map((item) => (
          <List key={item.uid} dense>
            <ListItem
              disableGutters
              secondaryAction={
                <IconButton onClick={() => handleDelete(item.uid)}>
                  <DeleteRounded />
                </IconButton>
              }
            >
              <ListItemText primary={`${item.hours} óra - ${item.price} Ft`} />
            </ListItem>
          </List>
        ))}
        <Typography sx={{ mt: 4 }}>Új bérlet típus:</Typography>
        <Form
          fields={subscriptionTypeFormFields}
          onSubmit={handleSubmit}
          inline
          gap={1}
          sx={{ mt: 1 }}
          loading={loading}
        />
      </CardContent>
    </>
  );
};

export default SubscriptionListAdmin;
