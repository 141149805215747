import React, { useCallback, useState } from 'react';
import OptionsContext from './OptionsContext';

const OptionsContextProvider = ({ children }) => {
  const [options, setOptions] = useState();

  const getLanguage = useCallback(
    (key) => {
      const currentOption = options?.languages.find((item) => {
        return item.key === key;
      });
      return currentOption?.value || '';
    },
    [options]
  );

  const getOption = useCallback(
    (option, key) => {
      const currentOption = options?.[option].find((item) => {
        return item.key === key;
      });
      return currentOption;
    },
    [options]
  );

  const getOptionValue = useCallback(
    (option, key) => {
      const currentOption = options?.[option].find((item) => {
        return item.key === key;
      });
      return currentOption?.value || '';
    },
    [options]
  );

  const renderOptionList = (values, options) => {
    if (!values) {
      return '';
    }
    const stringifiedValues = values.map((v) => v.toString());
    const selectedOptions = options.filter((option) => stringifiedValues.includes(option.key));
    return selectedOptions.map((option) => option.value).join(', ');
  };

  return (
    <OptionsContext.Provider
      value={{
        getOption,
        getOptionValue,
        setOptions,
        languages: options?.languages || [],
        getLanguage,
        userStatuses: options?.userStatuses || [],
        paymentProfiles: options?.paymentProfiles || [],
        courses: options?.courses || [],
        courseStatuses: options?.courseStatuses || [],
        subscriptionStatuses: options?.subscriptionStatuses || [],
        entryStatuses: options?.entryStatuses || [],
        roles: options?.roles || [],
        teachers: options?.teachers || [],
        courseGroupTypes: options?.courseGroupTypes || [],
        courseLocationTypes: options?.courseLocationTypes || [],
        transactionStatuses: options?.transactionStatuses || [],
        transactionTypes: options?.transactionTypes || [],
        renderOptionList
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

export default OptionsContextProvider;
