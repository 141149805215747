import { Slide } from '@mui/material';
import { useSnackbar } from 'notistack';

export const useError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueErrorSnackbar = (error) => {
    const message = error?.response?.data?.error || 'A művelet során várlatlan hiba történt';
    enqueueSnackbar(message, { variant: 'error', TransitionComponent: Slide });
  };

  return { enqueueErrorSnackbar };
};
