import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormError from './FormError';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

const CheckboxInput = ({ name, defaultValue, label = '', ...selectProps }) => {
  const formContext = useFormContext();

  return (
    <Controller
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <FormError error={error}>
            <FormControlLabel
              control={<MuiCheckbox onChange={onChange} onBlur={onBlur} checked={value} {...selectProps} />}
              label={label}
              sx={{ marginTop: -1 }}
            />
          </FormError>
        );
      }}
      name={name}
      control={formContext.control}
      defaultValue={typeof defaultValue == 'boolean' ? defaultValue : false}
    />
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string
};

export default CheckboxInput;
