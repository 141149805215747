import AuthPageLayout from './AuthPageLayout';
import { Form, Link } from 'ui';
import { useEffect, useState } from 'react';
import useViewer from '../../components/Viewer/ViewerContext/useViewer';
import { Typography } from '@mui/material';

import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';

const stepOneFields = [
  {
    name: 'email',
    type: 'text',
    fieldProps: {
      label: 'Email',
      autoFocus: true
    },
    rule: yup.string().email().required()
  }
];

const stepTwoFields = [
  {
    name: 'email',
    type: 'text',
    fieldProps: {
      label: 'Email',
      disabled: true
    },
    rule: yup.string().email().required()
  },
  {
    name: 'code',
    type: 'text',
    fieldProps: {
      label: 'Visszaigazoló kód',
      disabled: true
    },
    rule: yup.string().required()
  },
  {
    name: 'password',
    type: 'password',
    fieldProps: {
      label: 'Jelszó',
      autoFocus: true
    },
    rule: yup.string().min(8).max(64)
  }
];

const PasswordResetPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const { lostPassword } = useViewer();

  useEffect(() => {
    if (code && email) {
      setStep(3);
      return;
    }

    setStep(1);
  }, [code, email]);

  const handleSubmitStepOne = async (fields) => {
    setLoading(true);
    await lostPassword(fields);
    setStep(2);
    setLoading(false);
  };

  const handleSubmitStepTwo = async (fields) => {
    setLoading(true);
    const result = await lostPassword(fields);
    if (result) {
      setStep(4);
    } else {
      setStep(5);
    }

    setLoading(false);
  };

  return (
    <AuthPageLayout title='Elfelejtett jelszó'>
      {step === 1 && (
        <>
          <Typography sx={{ mb: 4 }}>
            Add meg az email címedet és ha korábban már regisztráltál elküldjük a további lépéseket az új jelszavad
            megadásához.
          </Typography>
          <Form
            fields={stepOneFields}
            onSubmit={handleSubmitStepOne}
            submitLabel='Küldés'
            fullWidthSubmit
            loading={loading}
          ></Form>
          <Typography variant='body2' sx={{ mt: 2, textAlign: 'center' }}>
            Ha mégis emlékszel a jelszavadra, akkor <Link to='/login'>lépj be</Link>
          </Typography>
        </>
      )}

      {step === 2 && (
        <>
          <Typography sx={{ mb: 3 }}>
            Ha a megadott email címről volt korábban regisztráció, megküldüldtünk egy emailt, amiben a jelszó módosítás
            további lépéseit találod.
          </Typography>
          <Typography variant='body2'>
            Ha mégis emlékszel a jelszavadra, akkor <Link to='/login'>lépj be</Link>
          </Typography>
        </>
      )}

      {step === 3 && (
        <>
          <Typography sx={{ mb: 2 }}>Adj meg egy új jeszót, ami minimum 8 karakter hosszú.</Typography>
          <Form
            fields={stepTwoFields}
            onSubmit={handleSubmitStepTwo}
            defaultValues={{
              code,
              email,
              password: ''
            }}
            submitLabel='Küldés'
            fullWidthSubmit
            loading={loading}
          ></Form>
          <Typography variant='body2' sx={{ mt: 2, textAlign: 'center' }}>
            Ha mégis emlékszel a jelszavadra, akkor <Link to='/login'>lépj be</Link>
          </Typography>
        </>
      )}

      {step === 4 && (
        <>
          <Typography sx={{ mb: 3 }}>
            A jelszavadat sikeresen módosítottad, a regisztráció során megadott email címeddel és az új jelszavaddal be
            tudsz lépni a portálra.
          </Typography>
          <Typography>
            <Link to='/login'>Vissza a belepés oldalra!</Link>
          </Typography>
        </>
      )}

      {step === 5 && (
        <>
          <Typography sx={{ mb: 3 }}>
            A jelszó módosításakor valami hiba történt, kérlek vedd fel a kapcsolatot velünk!
          </Typography>
          <Typography>
            <Link to='/login'>Vissza a belepés oldalra!</Link>
          </Typography>
        </>
      )}
    </AuthPageLayout>
  );
};

export default PasswordResetPage;
