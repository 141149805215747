export const validateInvoiceFields = (viewer) => {
  console.log({ viewer });
  if (viewer?.isCompany === '1') {
    return !!(
      viewer?.invoiceName &&
      viewer?.invoiceCountry &&
      viewer?.invoiceCity &&
      viewer?.invoiceAddress &&
      viewer?.invoiceZip &&
      viewer?.invoiceTaxNumber
    );
  } else {
    return !!(
      viewer?.invoiceName &&
      viewer?.invoiceCountry &&
      viewer?.invoiceCity &&
      viewer?.invoiceAddress &&
      viewer?.invoiceZip
    );
  }
};
