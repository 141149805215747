import { LinearProgress, Tooltip, Box } from '@mui/material';
import { Link } from 'ui';
import StatusChip from '../Common/StatusChip';
import { getHourAndMinutesFromMinutes, DATE_FORMATS, formatDate } from 'utils/date';

const useSubscriptionFields = (hideStudent) => {
  return [
    {
      name: 'userName',
      showInTable: !hideStudent,
      cellRenderer: (row) => (
        <Link underline='hover' sx={{ fontWeight: 700 }} to={`/students/${row.userId}`}>
          {row.userName}
        </Link>
      ),
      fieldProps: {
        label: 'Tanuló'
      }
    },
    {
      name: 'courseName',
      showInTable: true,
      cellRenderer: (row) => (
        <Link underline='hover' sx={{ fontWeight: 700 }} to={`/courses/${row.courseId}`}>
          {row.courseName}
        </Link>
      ),
      fieldProps: {
        label: 'Tanfolyam'
      }
    },
    {
      name: 'start',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.start ? formatDate(row.start, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'Start'
      }
    },
    {
      name: 'end',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.end ? formatDate(row.end, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'End'
      }
    },
    {
      name: 'hours',
      type: 'number',
      showInTable: true,
      cellRenderer: (row) => row.hours,
      fieldProps: {
        label: 'Órák'
      }
    },
    {
      name: 'usage',
      showInTable: true,
      cellRenderer: (row) => (
        <Tooltip
          placement='top'
          arrow
          title={`${getHourAndMinutesFromMinutes(row.minutesUsed)} / ${getHourAndMinutesFromMinutes(
            row.hours * 60 - row.minutesUsed
          )}`}
        >
          <Box sx={{ height: 32, paddingTop: 1.5 }}>
            <LinearProgress
              variant='determinate'
              value={(row.minutesUsed / (row.hours * 60)) * 100}
              sx={{ borderRadius: 2, height: '0.5rem' }}
            />
          </Box>
        </Tooltip>
      ),
      fieldProps: {
        label: 'Felhasználás'
      }
    },
    {
      name: 'status',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='subscription' />,
      fieldProps: {
        label: 'Státusz'
      }
    }
  ];
};

export default useSubscriptionFields;
