import ConfirmButton from '../../ui/ConfirmButton/ConfirmButton';
import useSubscriptionApi from './useSubscriptionApi';
import { useState } from 'react';

export const SetTransactionPayedButton = ({ transactionId, setRefetchTrigger }) => {
  const { processSubscription } = useSubscriptionApi();
  const [isLoading, setIsLoading] = useState(false);
  const handlePayment = async () => {
    setIsLoading(true);
    await processSubscription(transactionId);
    setRefetchTrigger((prev) => prev + 1);
    setIsLoading(false);
  };
  return (
    <ConfirmButton
      onConfirm={handlePayment}
      variant='outlined'
      size='small'
      title={isLoading ? 'Loading...' : 'Fizetettre állítás'}
      disabled={isLoading}
      sx={{ whiteSpace: 'nowrap' }}
    />
  );
};
