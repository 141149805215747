import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import * as moment from 'moment';
import 'moment/locale/hu';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import theme from 'theme/theme';
import ApiContextProvider from 'api/ApiContextProvider';
import ViewerContextProvider from 'components/Viewer/ViewerContext/ViewerContextProvider';

import Routes from 'components/Routes/Routes';
import OptionsContextProvider from 'components/Options/OptionsContext/OptionsContextProvider';
import { DialogContextProvider } from 'components/Common/DialogContext/DialogContext';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('hu')}>
            <ApiContextProvider>
              <OptionsContextProvider>
                <ViewerContextProvider>
                  <DialogContextProvider>
                    <Routes />
                  </DialogContextProvider>
                </ViewerContextProvider>
              </OptionsContextProvider>
            </ApiContextProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
