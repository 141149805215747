import { Stack, Hidden, IconButton } from '@mui/material';
import { Breadcrumbs, Title } from 'ui';
import CurrentUser from 'components/CurrentUser/CurrentUser';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const Header = ({ route }) => {
  const { showHelp, toggleHelp } = useViewer();
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        bgcolor: 'white',
        borderColor: 'divider',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        px: { xs: 1, md: 3 },
        py: { xs: 1, md: 2 }
      }}
    >
      <Stack direction='column'>
        {route.breadcrumbs && <Breadcrumbs breadcrumbs={route.breadcrumbs} />}
        <Title title={route.title || '?'} gutterBottom={0} variant='h5'></Title>
      </Stack>

      <Stack direction='row' gap={1}>
        <IconButton onClick={toggleHelp}>{showHelp ? <HelpRoundedIcon /> : <HelpOutlineRoundedIcon />}</IconButton>
        <Hidden mdUp implementation='css'>
          <CurrentUser />
        </Hidden>
      </Stack>
    </Stack>
  );
};

export default Header;
