import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import useTranslation from '../../hooks/useTranslation';

const Breadcrumbs = ({ breadcrumbs, hideHome }) => {
  const { t } = useTranslation();
  const bcList = hideHome ? breadcrumbs : [{ title: t('dashboard.title'), path: '/' }, ...breadcrumbs];
  return (
    <MuiBreadcrumbs separator={<NavigateNext fontSize='small' />} sx={{ mb: 0, fontSize: '0.875rem' }}>
      {bcList.map((item, index) =>
        item.path ? (
          <Link color='inherit' component={RouterLink} to={item.path} key={index} underline='hover'>
            {item.title}
          </Link>
        ) : (
          <Typography color='textPrimary' key={index}>
            {item.title}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
