import { Stack } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import UpcomingEventCard from './UpcomingEventCard';

const UpcomingEvents = ({ events }) => {
  const { t } = useTranslation();

  const handleJoin = (location) => {
    window.open(location, '_blank');
  };

  return (
    <div className='upcoming-events'>
      <h2>{t('dashboard.upcoming-classes')}</h2>
      <Stack gap={2}>
        {events.map((event) => (
          <UpcomingEventCard key={event.id} event={event} handleJoin={handleJoin} />
        ))}
      </Stack>
    </div>
  );
};

export default UpcomingEvents;
