import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import huLocale from '@fullcalendar/core/locales/hu';

import { CalendarContainer } from './Calendar.theme';

const Calendar = ({
  onClick = () => {},
  calendarRef,
  events,
  eventAdd,
  eventChange,
  eventRemove,
  onDateSelect,
  eventContent,
  eventClick,
  constraintGroupId,
  selectOverlap = true,
  eventOverlap = true,
  selectMirror,
  selectable = true,
  selectAllow = () => true,
  editable = true,
  headerToolbar = {},
  customButtons
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    calendarRef.current.getApi().changeView(matches ? 'timeGridWeek' : 'timeGridDay');
  }, [matches]);

  return (
    <CalendarContainer onClick={onClick}>
      <FullCalendar
        ref={calendarRef}
        height='100%'
        nowIndicator={true}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={matches ? 'timeGridWeek' : 'timeGridDay'}
        weekends={true}
        firstDay={1}
        headerToolbar={headerToolbar}
        events={events}
        eventAdd={eventAdd}
        eventChange={eventChange}
        eventRemove={eventRemove}
        eventClick={eventClick}
        editable={editable}
        selectable={selectable}
        select={onDateSelect}
        selectAllow={selectAllow}
        allDaySlot={false}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false
        }}
        slotMinTime='07:00:00'
        slotMaxTime='21:00:00'
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: false,
          hour12: false
        }}
        eventContent={eventContent}
        eventConstraint={constraintGroupId}
        selectConstraint={constraintGroupId}
        selectOverlap={selectOverlap}
        eventOverlap={eventOverlap}
        selectMirror={selectMirror}
        locale={huLocale}
        customButtons={customButtons}
      />
    </CalendarContainer>
  );
};

export default Calendar;
