import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { readFileAsync } from '../../../utils/assets';

const FileSelect = ({ children, accept, onOpenFile, onDragOver, onDragEnter, onDragLeave, multiple, disabled }) => {
  const uploadRef = useRef();

  const onSelectFile = (event) => {
    onOpenFile && onOpenFile(event.target.files[0]);

    // const target = event.target;
    // Array.from(target.files).forEach(file => {
    //     readFileAsync(file).then(data => {
    //         onOpenFile && onOpenFile(file, data);
    //     });
    // });
    // target.value = null;
  };

  const onUploadClick = () => {
    uploadRef.current.dispatchEvent(new MouseEvent('click'));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    Array.from(event.dataTransfer.files).forEach((file) => {
      readFileAsync(file).then((data) => {
        onOpenFile && onOpenFile(file, data);
      });
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDragOver && onDragOver();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDragEnter && onDragEnter();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDragLeave && onDragLeave();
  };

  return (
    <span
      onClick={onUploadClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <input
        disabled={disabled}
        type='file'
        multiple={multiple}
        onChange={onSelectFile}
        ref={uploadRef}
        style={{ display: 'none' }}
        accept={accept}
      />
      {children}
    </span>
  );
};

FileSelect.propTypes = {
  accept: PropTypes.string,
  onOpenFile: PropTypes.func.isRequired, // (file:Blob, url:DataUrl)
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onDragOver: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func
};

FileSelect.defaultProps = {
  accept: '*/*'
};

export default FileSelect;
