import { Box, Hidden, Stack } from '@mui/material';
import Navigation from './Navigation';
import logo from 'images/up2you-logo.svg';

import CurrentUser from 'components/CurrentUser/CurrentUser';
import { Link } from 'ui';

const NavigationBar = () => {
  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        width: { xs: '100vw', md: 80 },
        height: { xs: 64, md: '100dvh' },
        bgcolor: 'primary.main',
        py: 4,
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Hidden mdDown implementation='css'>
        <Box>
          <Link to='/'>
            <img src={logo} />
          </Link>
        </Box>
      </Hidden>
      <Navigation />
      <Hidden mdDown implementation='css'>
        <CurrentUser />
      </Hidden>
    </Stack>
  );
};

export default NavigationBar;
