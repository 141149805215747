import formControlMap from './controls/formControlMap';
import * as yup from 'yup';

export const getFieldComponent = (fieldParams, loading, defaultValue, readOnly) => {
  const { name, type, fieldProps, index, options, url } = fieldParams;
  const Field = formControlMap[type];
  if (!Field) {
    console.warn('Field not found with type', type);
    return null;
  }

  return (
    <Field
      key={name}
      name={name}
      index={index}
      options={options}
      defaultValue={defaultValue}
      readOnly={readOnly}
      url={url}
      {...fieldProps}
    />
  );
};

export const getYupSchema = (fields) => {
  const getSchema = (fieldList) =>
    yup.object().shape(
      (fieldList || []).reduce((acc, fieldParams) => {
        const { name, rule, arrayFieldParams } = fieldParams;
        if (arrayFieldParams) {
          const arrayWideRule = rule || yup.array();
          const arrayFieldsSchema = getSchema(arrayFieldParams?.fields);
          return { ...acc, [name]: arrayWideRule.of(arrayFieldsSchema) };
        }

        if (!rule) {
          return { ...acc, [name]: yup.mixed() };
        }

        return { ...acc, [name]: rule };
      }, {})
    );
  return getSchema(fields);
};
