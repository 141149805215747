import React from 'react';
import { Typography, LinearProgress, Box, Stack, Card } from '@mui/material';
import { getHourAndMinutesFromMinutes, formatDate, DATE_FORMATS } from 'utils/date';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const SubscriptionCardStudent = ({ subscription }) => {
  if (!subscription) {
    return null;
  }

  const minutesAvailable = getHourAndMinutesFromMinutes(subscription.minutesAvailable);

  return (
    <Card sx={{ p: 2 }}>
      <Stack direction='row' alignItems='center' justifyContent='flex-start' gap={2} sx={{ mb: 3 }}>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ bgcolor: 'primary.light', borderRadius: 1, height: '3rem', width: '3rem' }}
        >
          <ConfirmationNumberOutlinedIcon fontSize='large' />
        </Stack>
        <Box>
          <Typography variant='h6' sx={{ fontWeight: 700 }}>
            Havi bérlet
          </Typography>
          <Typography variant='body2'>
            {subscription.startDate !== null ? (
              <>
                {formatDate(subscription.startDate, DATE_FORMATS.shortDate)} -{' '}
                {formatDate(subscription.endDate, DATE_FORMATS.shortDate)}
              </>
            ) : (
              'A bérlet még inaktív'
            )}
          </Typography>
        </Box>
      </Stack>

      <Typography variant='body2' gutterBottom>
        <Box sx={{ fontSize: '1.5rem', display: 'inline' }}>
          {minutesAvailable[0] === '-' ? '0 perc' : minutesAvailable}
        </Box>{' '}
        <Box sx={{ color: 'text.secondary', display: 'inline' }}>
          / {getHourAndMinutesFromMinutes(subscription.minutes)}
        </Box>
      </Typography>
      <LinearProgress
        variant='determinate'
        value={(subscription.minutesUsed / subscription.minutes) * 100}
        sx={{ borderRadius: 2, mt: 1, height: '0.5rem' }}
      />
    </Card>
  );
};

export default SubscriptionCardStudent;
