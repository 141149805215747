import React, { useState } from 'react';
import { Form } from '../../ui';
import useEventFormFields from './useEventFormFields';
import useEventApi from './useEventApi';
import useTranslation from '../../hooks/useTranslation';
import { localIsoStringToUtcTimestamp } from 'utils/date';

const EventForm = ({ course, onCancel, onSave, selectedEvent }) => {
  const eventFields = useEventFormFields();
  const [loading, setLoading] = useState(false);
  const isEditMode = selectedEvent?.event?.id;
  const calendarApi = selectedEvent?.view?.calendar;
  const { createEvent, updateEvent, deleteEvent } = useEventApi();
  const { t } = useTranslation();

  const defaultValues = {
    capacity: selectedEvent?.event?.extendedProps?.capacity,
    teacher_ids: selectedEvent?.event?.extendedProps?.teacher_ids,
    courseEventLocationType: selectedEvent?.event?.extendedProps?.courseEventLocationType,
    courseEventOnlineLocation: selectedEvent?.event?.extendedProps?.courseEventOnlineLocation,
    courseEventPersonalLocation: selectedEvent?.event?.extendedProps?.courseEventPersonalLocation
  };

  const handleSubmit = async (fields) => {
    setLoading(true);

    if (isEditMode) {
      await updateEvent(isEditMode, {
        start: localIsoStringToUtcTimestamp(selectedEvent?.event?.startStr),
        end: localIsoStringToUtcTimestamp(selectedEvent?.event?.endStr),
        capacity: fields.capacity,
        course_id: course.uid,
        teacher_ids: fields.teacher_ids,
        location_type: fields.courseEventLocationType,
        online_location: fields.courseEventOnlineLocation,
        personal_location: fields.courseEventPersonalLocation
      });
    } else {
      await createEvent({
        start: localIsoStringToUtcTimestamp(selectedEvent?.startStr),
        end: localIsoStringToUtcTimestamp(selectedEvent?.endStr),
        capacity: fields.capacity,
        course_id: course.uid,
        teacher_ids: fields.teacher_ids,
        location_type: fields.courseEventLocationType,
        online_location: fields.courseEventOnlineLocation,
        personal_location: fields.courseEventPersonalLocation
      });
      console.log(selectedEvent?.startStr);
    }

    if (calendarApi) {
      calendarApi.unselect(); // clear date selection
      calendarApi.refetchEvents();
    }

    onSave();
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteEvent(isEditMode);
    setLoading(false);
    if (calendarApi) {
      calendarApi.unselect(); // clear date selection
      calendarApi.refetchEvents();
    }

    onSave();
  };

  return (
    <Form
      fields={eventFields}
      onSubmit={handleSubmit}
      onExtraAction={isEditMode ? () => handleDelete() : null}
      extraActionLabel='Törlés'
      onAltAction={onCancel}
      submitLabel={isEditMode ? t('calendar.update-event') : t('calendar.create-new-event')}
      loading={loading}
      defaultValues={defaultValues}
      sx={{ mt: 2 }}
    />
  );
};

export default EventForm;
