import React, { useRef } from 'react';
import axios from 'axios';
import ApiContext from './ApiContext';
import { useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';

const ApiContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const tokenRef = useRef(localStorage.getItem('viewer') || null);
  // const { enqueueSnackbar } = useSnackbar();

  const updateToken = (token) => (tokenRef.current = token);

  const instance = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_API_BASE_URL,
    mode: 'no-cors',
    headers: {
      'X-Client': window.location.host
    }
  });

  instance.interceptors.request.use((config) => {
    config.headers['X-Authorization'] = tokenRef.current;
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (response?.data?.message) {
        // enqueueSnackbar(response.data.message, { variant: 'success' });
      }

      return response;
    },
    (error) => {
      if (error.response.status === 401 && !['/login', '/user-confirm'].includes(window.location.pathname)) {
        navigate({ pathname: '/login' });
      } else if (error.response.status === 404) {
        navigate('/404', { replace: true });
      }
      throw error;
    }
  );

  const createFilterParams = (filters) => {
    const filterToSend = filters
      ? Object.keys(filters).reduce(
          (acc, key) =>
            filters[key]
              ? {
                  ...acc,
                  [key]: filters[key]
                }
              : acc,
          {}
        )
      : null;

    return filterToSend && Object.keys(filterToSend).length ? `?${new URLSearchParams(filterToSend).toString()}` : '';
  };

  return (
    <ApiContext.Provider value={{ token: tokenRef.current, updateToken, api: instance, createFilterParams }}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;
