import { Box, Typography } from '@mui/material';
import React from 'react';
import Spinner from 'ui/Spinner/Spinner';

const PageLoader = ({ text }) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}
    >
      <Spinner />
      {text && (
        <Typography variant='body1' sx={{ mt: 1 }}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default PageLoader;
