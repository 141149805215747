import { Box, IconButton, Stack } from '@mui/material';
import Header from './Header';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const InnerLayout = ({ route, children }) => {
  const { showHelp, toggleHelp } = useViewer();
  return (
    <>
      <Stack direction='column' sx={{ height: '100vh' }}>
        <Header route={route} />
        <Stack sx={{ flexGrow: 1, overflowY: 'auto', p: { xs: 1, md: 4 }, pb: { xs: 10 } }} direction='column'>
          {showHelp && route.helpComponent && (
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderColor: 'divider',
                borderStyle: 'solid',
                borderWidth: 1,
                p: 2,
                position: 'relative',
                borderRadius: 1
              }}
            >
              <IconButton onClick={toggleHelp} sx={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseRoundedIcon />
              </IconButton>
              {route.helpComponent}
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Stack>
      </Stack>
    </>
  );
};

export default InnerLayout;
