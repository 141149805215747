import { Box, Card, Stack, Typography } from '@mui/material';
import CourseMeta from 'components/Course/CourseMeta';
import { Button } from 'ui';
import { isToday, formatDate, DATE_FORMATS } from 'utils/date';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import { Link } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';

const UpcomingEventCard = ({ event, handleJoin }) => {
  const { t } = useTranslation();

  const locationType = event.location_type || event.courseEventsLocationType || event.courseLocationType;
  const onlineLocation = event.courseEventsOnlineLocation || event.courseOnlineLocation;
  // const personalLocation = event.courseEventsPersonalLocation || event.coursePersonalLocation;

  return (
    <Card
      key={event.id}
      sx={{
        borderColor: isToday(event.start) ? 'primary.main' : 'divider',
        borderStyle: 'solid',
        borderWidth: 1
      }}
    >
      <Stack direction='row' gap={2}>
        <Stack
          sx={{
            borderColor: 'divider',
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            p: 2,
            width: '100px'
          }}
          justifyContent='center'
          alignItems='center'
        >
          <Box sx={{ color: 'text.secondary' }}>{formatDate(event.start, DATE_FORMATS.month)}</Box>
          <Box sx={{ fontSize: '2rem' }}>{formatDate(event.start, DATE_FORMATS.day)}</Box>
        </Stack>

        <Stack justifyContent='center' alignItems='flex-start' flexGrow={1} sx={{ py: 1 }}>
          <Typography variant='body2' component='div' sx={{ color: 'text.secondary' }}>
            ● {formatDate(event.start, DATE_FORMATS.time)} - {formatDate(event.end, DATE_FORMATS.time)}
          </Typography>
          <Typography variant='body1' component='div' sx={{ fontWeight: 700, mb: 1 }}>
            {event.courseName}
          </Typography>

          <CourseMeta course={{ ...event, location, locationType }} />
        </Stack>

        <Stack justifyContent='center' alignItems='flex-start' gap={1} sx={{ p: 2, width: 140 }}>
          {['online', 'mixed'].includes(locationType) && (
            <Button
              size='small'
              color='primary'
              onClick={() => handleJoin(onlineLocation)}
              variant='text'
              startIcon={<AddBoxRoundedIcon />}
            >
              {t('courses.join')}
            </Button>
          )}
          {event.courseId && (
            <Button
              component={Link}
              to={`/courses/${event.courseId}`}
              size='small'
              color='secondary'
              variant='text'
              startIcon={<BookRoundedIcon />}
            >
              {t('courses.details')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default UpcomingEventCard;
