import TablePage from '../../components/Common/TablePage';
import useUserFields from '../../components/User/useUserFields';
import useUserApi from '../../components/User/useUserApi';
import useUserFilters from '../../components/User/useUserFilters';

const UsersPage = () => {
  const { getUsers, updateUser } = useUserApi();
  const fields = useUserFields();
  const filtersConfig = useUserFilters();

  return <TablePage fields={fields} onGetRows={getUsers} onUpdateRow={updateUser} filterConfig={filtersConfig} />;
};

export default UsersPage;
