import TablePage from '../../components/Common/TablePage';
import { useNavigate } from 'react-router-dom';
import useTeacherFields from '../../components/Teacher/useTeacherFields';
import useTeacherFilters from '../../components/Teacher/useTeacherFilters';
import useTeacherApi from 'components/Teacher/useTeacherApi';

const TeachersPage = () => {
  const { getTeachers } = useTeacherApi();
  const fields = useTeacherFields();
  const filtersConfig = useTeacherFilters();
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/teachers/${row.id}`);
  };

  return <TablePage fields={fields} onGetRows={getTeachers} onRowClick={handleRowClick} filterConfig={filtersConfig} />;
};

export default TeachersPage;
