import * as yup from 'yup';
import StatusChip from '../Common/StatusChip';
import useOptions from '../Options/OptionsContext/useOptions';

const useTeacherFields = () => {
  const { userStatuses } = useOptions();
  return [
    {
      name: 'name',
      cellRenderer: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'firstName',
      type: 'text',
      fieldProps: {
        label: 'Vezetéknév'
      },
      rule: yup.string().required()
    },
    {
      name: 'lastName',
      type: 'text',
      fieldProps: {
        label: 'Keresztnév'
      },
      rule: yup.string().required()
    },
    {
      name: 'email',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Email'
      },
      rule: yup.string().email().required()
    },
    {
      name: 'status',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='user' />,
      fieldProps: {
        label: 'Státusz',
        options: userStatuses
      },
      rule: yup.string().required()
    }
  ];
};

export default useTeacherFields;
