import { Box } from '@mui/material';
import TablePage from 'components/Common/TablePage';
import useEntryFields from './useEntryFields';
import useEntryApi from './useEntryApi';
import useEntryFilters from './useEntryFilters';
import useTranslation from 'hooks/useTranslation';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

const EntryList = ({ courseId }) => {
  const { getEntries, updateEntry, deleteEntry } = useEntryApi(courseId);
  const { viewerRoles, viewerHasRole } = useViewer();
  const fields = useEntryFields();
  const filtersConfig = useEntryFilters();
  const { t } = useTranslation();

  const handleUpdateEvent = async (id, entry) => {
    await updateEntry(id, {
      ...entry,
      course_id: courseId
    });
  };

  const tableProps = {
    fields,
    onGetRows: getEntries,
    onUpdateRow: viewerHasRole(viewerRoles.TEACHER) ? handleUpdateEvent : null,
    onDeleteRow: deleteEntry,
    deleteLabel: t('courses.cancel-entry'),
    updateMessage: t('courses.cancel-entry-message'),
    filterConfig: filtersConfig
  };

  return (
    <Box sx={{ mt: 4 }}>
      <TablePage {...tableProps} />
    </Box>
  );
};

export default EntryList;
