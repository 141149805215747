import { Button, Dialog, Title } from 'ui';

import React, { useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import CourseAdminMeta from 'components/Course/CourseAdminMeta';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

import CourseDetails from 'components/Course/CourseDetails';
import { Grid, Stack, Box, Tabs, Tab, Card } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Subscriptions from 'components/Subscription/Subscriptions';
import CourseForm from 'components/Course/CourseForm';
import CourseCalendar from 'components/Course/CourseCalendar';
import EntryList from 'components/Entry/EntryList';
import NextEntry from 'components/Entry/NextEntry';
import { useCourseContext } from './CourseContext';
import SubscriptionListStudent from 'components/Subscription/SubscriptionListStudent';
import CourseTransactionList from './CourseTransactionList';
import Toolbar from 'components/Layout/Toolbar';

const CoursePageLayout = () => {
  const { course, loading, refetchCourse } = useCourseContext();
  const [tab, setTab] = useState(0);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();
  const { viewerRoles, viewerHasRole, viewerRoleIs } = useViewer();

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveCourse = () => {
    refetchCourse();
    setDialogOpen(false);
  };

  const handleTabChange = (e, v) => {
    setTab(v);
  };

  return (
    <>
      {viewerHasRole(viewerRoles.TEACHER) && (
        <Toolbar>
          <CourseAdminMeta course={course} />
          {viewerHasRole(viewerRoles.ADMIN) && (
            <Button onClick={() => setDialogOpen(true)} startIcon={<EditTwoToneIcon />} variant='text' size='small'>
              {t('courses.edit-course')}
            </Button>
          )}
        </Toolbar>
      )}
      {!loading && (
        <>
          <Title title={course.name} gutterBottom={1} />

          <Tabs value={tab} onChange={handleTabChange} aria-label='Course tabs'>
            <Tab label={t('courses.details')} id='details' />
            <Tab label={t('courses.calendar')} id='calendar' />
            <Tab
              label={viewerRoleIs(viewerRoles.STUDENT) ? t('courses.my-entries') : t('courses.entries')}
              id='entries'
            />
            <Tab label={t('courses.subscription-title')} id='subscriptions' />
          </Tabs>

          {tab === 0 && (
            <Grid container sx={{ mt: 4 }} spacing={4}>
              <Grid item xs={12} lg={8} xl={9}>
                <Card elevation={0} variant='outlined' sx={{ p: 2 }}>
                  <CourseDetails />
                </Card>
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <Stack sx={{ flexGrow: 0, flexShrink: 0 }} direction='column' gap={2}>
                  {viewerRoleIs(viewerRoles.STUDENT) && <NextEntry onCalendarClick={() => setTab(1)} />}
                  {viewerRoleIs(viewerRoles.STUDENT) && <SubscriptionListStudent />}
                  <Subscriptions onCalendarClick={() => setTab(1)} />
                </Stack>
              </Grid>
            </Grid>
          )}

          {tab === 1 && (
            <Box>
              <CourseCalendar course={course} onSubscribe={() => setTab(0)} />
            </Box>
          )}

          {tab === 2 && (
            <Box>
              <EntryList courseId={course.uid} />
            </Box>
          )}

          {tab === 3 && (
            <Box>
              <CourseTransactionList courseId={course.uid} />
            </Box>
          )}

          <Dialog title={course.name} isOpen={isDialogOpen} onClose={handleCloseDialog} hideActions>
            <CourseForm onCancel={handleCloseDialog} onSave={handleSaveCourse} defaultValues={course} />
          </Dialog>
        </>
      )}
    </>
  );
};

export default CoursePageLayout;
