import { Stack } from '@mui/material';
import { useCourseContext } from 'components/Course/CourseContext';
import SubscriptionCardStudent from './SubscriptionCardStudent';

const SubscriptionListStudent = () => {
  const { subscriptions } = useCourseContext();

  if (subscriptions.length === 0) return null;

  return (
    <Stack gap={2}>
      {subscriptions.map((subscription) => (
        <SubscriptionCardStudent key={subscription.id} subscription={subscription} />
      ))}
    </Stack>
  );
};

export default SubscriptionListStudent;
