import * as yup from 'yup';

const subscriptionTypeFormFields = [
  {
    name: 'hours',
    type: 'text',
    fieldProps: {
      size: 'small',
      label: 'Óra'
    },
    rule: yup.string().required()
  },
  {
    name: 'price',
    type: 'text',
    fieldProps: {
      size: 'small',
      label: 'Ár'
    },
    rule: yup.string().required()
  }
];

export default subscriptionTypeFormFields;
