import { useEffect, useState, useCallback } from 'react';
import { Details } from 'ui';
import { useUserDetailsFields } from './userDetailsFields';
import useUserApi from '../useUserApi';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

const UserDetails = ({ userId }) => {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const { viewer, updateViewer } = useViewer();
  const userDetailsFields = useUserDetailsFields();
  const { getUser, updateUser } = useUserApi();

  const fetchUser = async (id) => {
    setLoading(true);
    const response = await getUser(id);
    setUser(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  const handleUpdate = useCallback(
    async (data) => {
      await updateUser(userId, data);
      if (viewer.id === userId) updateViewer(data);
      fetchUser(userId);
    },
    [updateUser]
  );

  return (
    <Details
      title='Személyes adatok'
      fields={userDetailsFields}
      data={user}
      onUpdate={handleUpdate}
      loading={isLoading}
    />
  );
};

export default UserDetails;
