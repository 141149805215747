import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Tile from './Tile';

const Tiles = ({ tiles, sx }) => {
  return (
    <Box
      sx={{
        ...sx,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 2
      }}
    >
      {tiles.map((tile, index) => (
        <Tile title={tile.title} icon={tile.icon} path={tile.path} key={index} />
      ))}
    </Box>
  );
};

Tiles.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      path: PropTypes.string
    })
  ).isRequired,
  sx: PropTypes.object
};

export default Tiles;
