import useViewer from 'components/Viewer/ViewerContext/useViewer';
import { Helmet } from 'react-helmet';

const BarionPixel = () => {
  const { viewer, viewerRoleIs, viewerRoles } = useViewer();
  const barionPixelId = viewer.barionPixel;

  if (!barionPixelId || !viewerRoleIs(viewerRoles.STUDENT)) {
    return null;
  }

  return (
    <Helmet>
      <script>
        {`
            // Create BP element on the window
            window["bp"] = window["bp"] || function () {
                (window["bp"].q = window["bp"].q || []).push(arguments);
            };
            window["bp"].l = 1 * new Date();
    
            // Insert a script tag on the top of the head to load bp.js
            scriptElement = document.createElement("script");
            firstScript = document.getElementsByTagName("script")[0];
            scriptElement.async = true;
            scriptElement.src = 'https://pixel.barion.com/bp.js';
            firstScript.parentNode.insertBefore(scriptElement, firstScript);
            window['barion_pixel_id'] = '${barionPixelId}';            

            // Send init event
            bp('init', 'addBarionPixelId', window['barion_pixel_id']);
            `}
      </script>

      <noscript>
        {`
        <img
          height='1'
          width='1'
          style='display:none'
          alt='Barion Pixel'
          src="https://pixel.barion.com/a.gif?ba_pixel_id='${barionPixelId}'&ev=contentView&noscript=1"
        />
        `}
      </noscript>
    </Helmet>
  );
};

export default BarionPixel;
