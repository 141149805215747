import StatusChip from 'components/Common/StatusChip';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const CourseAdminMeta = ({ course }) => {
  return (
    <>
      <StatusChip status={course.status} entity='course' size='small' icon={<CheckCircleOutlineRoundedIcon />} />
    </>
  );
};

export default CourseAdminMeta;
