import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const usePaymentProfilesApi = () => {
  const { api } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getPaymentProfiles = async () => {
    try {
      const { data } = await api({
        url: '/settings/payment-profiles/',
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createPaymentProfile = async (fields) => {
    try {
      const { data } = await api({
        url: '/settings/payment-profiles',
        method: 'post',
        data: fields
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updatePaymentProfile = async (id, fields) => {
    try {
      const { data } = await api({
        url: `/settings/payment-profiles/${id}`,
        method: 'put',
        data: fields
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deletePaymentProfile = async (id) => {
    try {
      const { data } = await api({
        url: `/settings/payment-profiles/${id}`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return { getPaymentProfiles, createPaymentProfile, updatePaymentProfile, deletePaymentProfile };
};

export default usePaymentProfilesApi;
