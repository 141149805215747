import TablePage from '../../components/Common/TablePage';
import { useNavigate } from 'react-router-dom';
import useStudentFilters from '../../components/Student/useStudentFilters';
import useStudentFields from '../../components/Student/useStudentFields';
import useStudentApi from 'components/Student/useStudentApi';

const StudentsPage = () => {
  const { getStudents } = useStudentApi();
  const fields = useStudentFields();
  const filterConfig = useStudentFilters();
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/students/${row.id}`);
  };

  return <TablePage fields={fields} onGetRows={getStudents} onRowClick={handleRowClick} filterConfig={filterConfig} />;
};

export default StudentsPage;
