import React, { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Button, Checkbox, FormControl, Menu, MenuItem } from '@mui/material';

const MultiSelectFilter = ({ label, id, filterOptions, filterValue, handleMultiSelectChange, icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMultiSelect = Array.isArray(filterValue);
  const badgeContent = isMultiSelect ? filterValue.length : filterValue.length ? 1 : 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (id, key) => () => {
    handleMultiSelectChange(id, key);

    if (!isMultiSelect) {
      handleClose();
    }
  };

  const updatedLabel =
    filterValue && !isMultiSelect ? filterOptions.find((option) => option.key === filterValue).value : label;

  return (
    <FormControl>
      <Box>
        <Button
          color='secondary'
          id={id}
          startIcon={
            <Badge badgeContent={badgeContent} color='secondary'>
              {createElement(icon, { color: badgeContent ? 'primary' : 'secondary' })}
            </Badge>
          }
          variant='text'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {updatedLabel}
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {filterOptions.map(({ key, value }) => (
            <MenuItem key={key} onClick={handleChange(id, key)} selected={!isMultiSelect && key === filterValue}>
              {isMultiSelect && (
                <Checkbox color='primary' checked={filterValue.some((fValue) => fValue === key)} sx={{ p: 0, mr: 1 }} />
              )}
              {value}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </FormControl>
  );
};

MultiSelectFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filterOptions: PropTypes.array,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleMultiSelectChange: PropTypes.func,
  icon: PropTypes.object
};

export default MultiSelectFilter;
