import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileInput from './FileInput';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Chip, FormHelperText } from '@mui/material';
import { CheckCircleRounded, CloudUploadRounded } from '@mui/icons-material';
import prettyBytes from 'pretty-bytes';
import FormError from './FormError';

const FormFileSelect = ({ name, defaultValue, multiple, disabled, accept, helperText }) => {
  const formContext = useFormContext();
  const [fileData, setFileData] = useState();
  return (
    <Controller
      render={({ field: { onChange, value: files = [] }, fieldState: { error } }) => {
        const isDisabled = (!multiple && files.length > 0) || disabled;
        return (
          <>
            <FileInput
              accept={accept}
              multiple={multiple}
              disabled={isDisabled}
              onOpenFile={(file) => {
                setFileData(file);
                return onChange(file);
              }}
            >
              <Button disabled={isDisabled} files={files} color='primary' startIcon={<CloudUploadRounded />}>
                File kiválasztása
              </Button>
            </FileInput>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            <FormError error={error} />
            {fileData && (
              <Chip
                icon={<CheckCircleRounded />}
                variant='outlined'
                label={`${fileData.name} (${prettyBytes(fileData.size)})`}
              />
            )}
          </>
        );
      }}
      name={name}
      control={formContext.control}
      defaultValue={defaultValue || []}
    />
  );
};

FormFileSelect.defaultProps = {
  multiple: false
};

FormFileSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.object,
      url: PropTypes.string
    })
  ),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  helperText: PropTypes.string
};

export default FormFileSelect;
