import usePaymentProfilesApi from 'components/PaymentProfile/usePaymentProfileApi';
import TablePage from '../../components/Common/TablePage';
import usePaymentProfileFields from 'components/PaymentProfile/usePaymentProfileFields';

const PaymentProfilePage = () => {
  const { getPaymentProfiles, createPaymentProfile, updatePaymentProfile, deletePaymentProfile } =
    usePaymentProfilesApi();
  const fields = usePaymentProfileFields();

  return (
    <TablePage
      fields={fields}
      onGetRows={getPaymentProfiles}
      onCreateRow={createPaymentProfile}
      onUpdateRow={updatePaymentProfile}
      onDeleteRow={deletePaymentProfile}
    />
  );
};

export default PaymentProfilePage;
