import React from 'react';
import { Breadcrumbs, Title } from '../../ui';
import useTranslation from '../../hooks/useTranslation';

const AdminPage = () => {
  const { t } = useTranslation();
  const breadcrumbs = [];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Title title={t('admin.title')} gutterBottom={1} />
    </>
  );
};

export default AdminPage;
