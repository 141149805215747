import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useEntryApi = (courseId = null) => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getEntries = async (filters) => {
    const url = courseId ? `/courses/${courseId}/entries` : '/entries';
    try {
      const { data } = await api({
        url: `${url}${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getNextEntry = async (courseId) => {
    try {
      const { data } = await api({
        url: `/courses/${courseId}/entries/next`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createEntry = async (entry) => {
    try {
      const { data } = await api({
        url: '/entries',
        method: 'post',
        data: entry
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateEntry = async (id, entry) => {
    console.log({ entry });
    const payload = {
      ...entry,
      start: entry.start,
      end: entry.end
    };
    try {
      const { data } = await api({
        url: `/entries/${id}`,
        method: 'put',
        data: payload
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deleteEntry = async (id) => {
    try {
      const { data } = await api({
        url: `/entries/${id}`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getEntries,
    getNextEntry,
    createEntry,
    updateEntry,
    deleteEntry
  };
};

export default useEntryApi;
