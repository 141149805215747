import { Link as MaterialLink, LinearProgress, Tooltip, Box } from '@mui/material';
import { Link } from 'ui';
import useOptions from '../Options/OptionsContext/useOptions';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import { getHourAndMinutesFromMinutes, formatDate, DATE_FORMATS } from 'utils/date';
import { SetTransactionPayedButton } from '../Subscription/SetTransactionPayedButton';

const useTransactionsFields = ({ hideCourse = false, hideStudent = false, setRefetchTrigger }) => {
  const { courses, getOptionValue } = useOptions();
  const { viewerRoleIs, viewerRoles } = useViewer();

  return [
    {
      name: 'createdOn',
      showInTable: true,
      cellRenderer: (row) => formatDate(row.createdOn, DATE_FORMATS.dateTime),
      fieldProps: {
        label: 'Dátum'
      }
    },
    ...(!hideCourse
      ? [
          {
            name: 'courseId',
            showInTable: true,
            cellRenderer: (row) => (
              <Link underline='hover' sx={{ fontWeight: 700 }} to={`/courses/${row.courseId}`}>
                {courses.find((item) => item.key === row.courseId)?.value || ''}
              </Link>
            ),
            fieldProps: {
              label: 'Tanfolyam'
            }
          }
        ]
      : []),
    ...(!hideStudent
      ? [
          {
            name: 'user',
            cellRenderer: (row) => (
              <Link underline='hover' sx={{ fontWeight: 700 }} to={`/students/${row.userId}`}>
                {row.userName}
              </Link>
            ),
            showInTable: true,
            fieldProps: {
              label: 'Felhasználó'
            }
          }
        ]
      : []),
    {
      name: 'price',
      showInTable: true,
      cellRenderer: (row) => parseInt(row.price).toLocaleString('hu'),
      fieldProps: {
        align: 'right',
        label: 'Ár'
      }
    },
    {
      name: 'start',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.start ? formatDate(row.start, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'Érv. kezdete',
        checkDisabled: (value) => !value
      }
    },
    {
      name: 'end',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.end ? formatDate(row.end, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'Érv. vége',
        checkDisabled: (value) => !value
      }
    },
    {
      name: 'hours',
      type: 'number',
      showInTable: true,
      cellRenderer: (row) => row.hours || row.quantity,
      fieldProps: {
        label: 'Órák',
        checkDisabled: (value) => !value
      }
    },
    {
      name: 'usage',
      showInTable: true,
      cellRenderer: (row) => (
        <>
          {row.subscriptionStatus === 'active' ? (
            <Tooltip
              placement='top'
              arrow
              title={`${getHourAndMinutesFromMinutes(row.minutesUsed)} / ${getHourAndMinutesFromMinutes(
                row.hours * 60 - row.minutesUsed
              )}`}
            >
              <Box sx={{ height: 32, paddingTop: 1.5 }}>
                <LinearProgress
                  variant='determinate'
                  value={(row.minutesUsed / (row.hours * 60)) * 100}
                  sx={{ borderRadius: 2, height: '0.5rem' }}
                />
              </Box>
            </Tooltip>
          ) : (
            <>-</>
          )}
        </>
      ),
      fieldProps: {
        label: 'Felhasználás'
      }
    },
    ...(!viewerRoleIs(viewerRoles.STUDENT)
      ? [
          {
            name: 'status',
            showInTable: true,
            cellRenderer: (row) => <>{getOptionValue('transactionStatuses', row.status)}</>,
            fieldProps: {
              label: 'Tr. státusz'
            }
          }
        ]
      : []),
    ...(viewerRoleIs(viewerRoles.ADMIN)
      ? [
          {
            name: 'statusUpdate',
            showInTable: true,
            cellRenderer: (row) => (
              <>
                {row.status === 'Prepared' && row.transactionType === 'Transfer' && (
                  <SetTransactionPayedButton transactionId={row.id} setRefetchTrigger={setRefetchTrigger} />
                )}
              </>
            ),
            fieldProps: {
              label: ''
            }
          }
        ]
      : []),
    {
      name: 'subscriptionStatus',
      showInTable: true,
      cellRenderer: (row) =>
        row.subscriptionStatus ? <>{getOptionValue('subscriptionStatuses', row.subscriptionStatus)}</> : '-',
      fieldProps: {
        label: 'Bérlet státusz'
      }
    },
    {
      name: 'transactionType',
      showInTable: true,
      cellRenderer: (row) =>
        row.transactionType ? <>{getOptionValue('transactionTypes', row.transactionType)}</> : '-',
      fieldProps: {
        label: 'Fizetés módja'
      }
    },
    {
      name: 'invoiceUrl',
      showInTable: true,
      cellRenderer: (row) =>
        row.invoiceUrl ? (
          <MaterialLink
            onClick={(e) => e.stopPropagation()}
            href={`${process.env.REACT_APP_API_BASE_URL}download/${row.invoiceUrl}`}
          >
            Letöltés
          </MaterialLink>
        ) : (
          '-'
        ),
      fieldProps: {
        label: 'Számla'
      }
    }
  ];
};

export default useTransactionsFields;
