import { useEffect, useState, useCallback } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Details } from 'ui';
import {
  useStudentInvoiceFieldsCompany,
  useStudentInvoiceFieldsPerson
} from 'components/User/UserDetails/userDetailsFields';
import useStudentApi from './useStudentApi';

const StudentInvoiceDetails = ({ studentId, onChange, justifyBetween }) => {
  const [isLoading, setLoading] = useState(false);
  const [studentProfile, setStudentProfile] = useState([]);
  const [isCompany, setIsCompany] = useState();
  const studentInvoiceFields = isCompany ? useStudentInvoiceFieldsCompany() : useStudentInvoiceFieldsPerson();
  const { updateStudentProfile, getStudentProfile } = useStudentApi();

  const fetchStudentProfile = async (id) => {
    setLoading(true);
    const response = await getStudentProfile(id);
    setStudentProfile(response.studentProfile);
    if (onChange) onChange(response.studentProfile);
    setIsCompany(response.studentProfile.isCompany === '1');
    setLoading(false);
  };

  useEffect(() => {
    fetchStudentProfile(studentId);
  }, [studentId]);

  const handleUpdate = useCallback(
    async (data) => {
      await updateStudentProfile(studentId, { ...data, isCompany });
      fetchStudentProfile(studentId);
    },
    [isCompany, updateStudentProfile]
  );

  return (
    <Details
      title='Számlázási adatok'
      fields={studentInvoiceFields}
      data={studentProfile}
      onUpdate={handleUpdate}
      loading={isLoading}
      justifyBetween={justifyBetween}
      updateMessage={
        <FormControlLabel
          control={<Checkbox checked={isCompany} onChange={() => setIsCompany((previousValue) => !previousValue)} />}
          label='Céges számlázás'
        />
      }
    />
  );
};

export default StudentInvoiceDetails;
