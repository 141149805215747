import React from 'react';
import TextInput from './TextInput';
import FileSelect from './FileSelect';
import SelectInput from './SelectInput';
// from './TagsInput';
import DateInput from './DateInput';
import DateTimeInput from './DateTimeInput';
import CheckboxInput from './CheckboxInput';
import AutoComplete from './AutoComplete';

const formFieldMap = {
  email: (props) => <TextInput type='email' {...props} />,
  password: (props) => <TextInput type='password' {...props} />,
  text: (props) => <TextInput type='text' {...props} />,
  textarea: (props) => <TextInput type='text' multiline {...props} />,
  number: (props) => <TextInput type='number' {...props} />,
  file: (props) => <FileSelect {...props} />,
  multiselect: (props) => <SelectInput multiselect {...props} />,
  select: (props) => <SelectInput {...props} />,
  // tags: props => <TagsInput {...props} />,
  date: (props) => <DateInput {...props} />,
  datetime: (props) => <DateTimeInput {...props} />,
  checkbox: (props) => <CheckboxInput {...props} />,
  autocomplete: (props) => <AutoComplete {...props} />
};

export default formFieldMap;
