import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

const Tile = ({ title, icon, path }) => {
  return (
    <Box
      component={Link}
      to={path}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'divider',
        aspectRatio: '1 / 1',
        bgcolor: 'background.paper',
        borderRadius: 1,
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'text.primary',
        ':hover': {
          borderColor: 'primary.main'
        },
        svg: {
          fontSize: '3rem',
          color: 'primary.main'
        }
      }}
    >
      {icon}
      <Typography sx={{ mt: 0.5, fontWeight: 700 }}>{title}</Typography>
    </Box>
  );
};

Tile.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  path: PropTypes.string
};

export default Tile;
