import { Grid } from '@mui/material';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import StudentInvoiceDetails from 'components/Student/StudentInvoiceDetails';
import UserDetails from 'components/User/UserDetails/UserDetails';

const MyDetailsPage = () => {
  const { viewer, viewerRoleIs, viewerRoles } = useViewer();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <UserDetails userId={viewer.id} />
      </Grid>
      {viewerRoleIs(viewerRoles.STUDENT) && (
        <Grid item xs={12} md={6}>
          <StudentInvoiceDetails studentId={viewer.id} />
        </Grid>
      )}
    </Grid>
  );
};

export default MyDetailsPage;
