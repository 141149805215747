import * as yup from 'yup';
import useOptions from 'components/Options/OptionsContext/useOptions';

const useCourseFormFields = (mode = 'new') => {
  const { languages, courseGroupTypes, courseLocationTypes } = useOptions();
  return [
    {
      name: 'name',
      type: 'text',
      fieldProps: {
        label: 'Név'
      },
      rule: yup.string().required('Adj meg egy nevet')
    },
    {
      name: 'description',
      type: 'text',
      fieldProps: {
        label: 'Leírás',
        multiline: true
      },
      rule: yup.string().required('Adj meg leírást')
    },
    {
      name: 'language',
      type: 'select',
      fieldProps: {
        label: 'Nyelv',
        options: languages
      },
      rule: yup.string().required()
    },
    {
      name: 'groupType',
      type: 'select',
      fieldProps: {
        label: 'Oktatási forma',
        options: courseGroupTypes
      },
      rule: yup.string().required()
    },
    {
      name: 'locationType',
      type: 'select',
      fieldProps: {
        label: 'Helyszín típusa',
        options: courseLocationTypes
      },
      rule: yup.string().required()
    },
    {
      name: 'online_location',
      type: 'text',
      fieldProps: {
        label: 'Online helyszín'
      },
      rule: yup.string()
    },
    {
      name: 'personal_location',
      type: 'text',
      fieldProps: {
        label: 'Személyes helyszín'
      },
      rule: yup.string()
    },
    ...(mode === 'edit'
      ? [
          {
            name: 'status',
            type: 'select',
            fieldProps: {
              label: 'Sátusz',
              options: [
                { key: '0', value: 'Inaktív' },
                { key: '1', value: 'Aktív' },
                { key: '9', value: 'Archivált' }
              ]
            },
            rule: yup.string()
          }
        ]
      : [])
  ];
};

export default useCourseFormFields;
