import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useUserApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getUsers = async (filters) => {
    try {
      const { data } = await api({
        url: `/users${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getUser = async (id) => {
    try {
      const { data } = await api({
        url: `/users/${id}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  // const createPaymentProfile = async (fields) => {
  //   try {
  //     const { data } = await api({
  //       url: '/settings/payment-profiles',
  //       method: 'post',
  //       data: fields
  //     });
  //     return data;
  //   } catch (error) {
  //     enqueueErrorSnackbar(error);
  //   }
  // };

  const updateUser = async (id, fields) => {
    try {
      const { data } = await api({
        url: `/users/${id}`,
        method: 'put',
        data: fields
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  // const deletePaymentProfile = async (id) => {
  //   try {
  //     const { data } = await api({
  //       url: `/settings/payment-profiles/${id}`,
  //       method: 'delete'
  //     });
  //     return data;
  //   } catch (error) {
  //     enqueueErrorSnackbar(error);
  //   }
  // };

  return { getUser, getUsers, updateUser };
};

export default useUserApi;
