import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { NavigateBeforeRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Title = ({ title, variant, component, gutterBottom, gutterTop, backUrl, backText, children }) => {
  const varComponent = variant.includes('body') ? 'p' : variant;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 32,
        mb: gutterBottom || 0,
        mt: gutterTop || 0
      }}
    >
      <div>
        {backUrl && (
          <Button
            variant='outlined'
            to={backUrl}
            component={Link}
            startIcon={<NavigateBeforeRounded />}
            size='small'
            sx={{
              mb: 2
            }}
          >
            {backText}
          </Button>
        )}
        <Typography variant={variant} component={component || varComponent}>
          {title}
        </Typography>
      </div>
      {children}
    </Box>
  );
};

Title.defaultProps = {
  variant: 'h1',
  gutterBottom: 3,
  gutterTop: 0,
  backText: 'Vissza'
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  component: PropTypes.string,
  gutterBottom: PropTypes.number,
  gutterTop: PropTypes.number,
  backUrl: PropTypes.string,
  backText: PropTypes.string
};

export default Title;
