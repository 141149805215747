import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';

const FormError = ({ error, children }) => {
  const hasError = !!error;
  return (
    <FormControl fullWidth error={hasError}>
      {children}
      {hasError && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

FormError.propTypes = {
  error: PropTypes.object
};

export default FormError;
