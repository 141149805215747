import { CourseContextProvider } from 'components/Course/CourseContext';
import CoursePageLayout from 'components/Course/CoursePageLayout';

const CoursePage = () => {
  return (
    <>
      <CourseContextProvider>
        <CoursePageLayout />
      </CourseContextProvider>
    </>
  );
};

export default CoursePage;
