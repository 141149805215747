import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useDashboardApi = () => {
  const { api } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getDashboard = async () => {
    try {
      const { data } = await api({
        url: '/dashboard',
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return { getDashboard };
};

export default useDashboardApi;
