import React from 'react';
import { Typography } from '@mui/material';
import CourseMeta from './CourseMeta';
import { useCourseContext } from 'components/Course/CourseContext';

const CourseDetails = () => {
  const { course } = useCourseContext();
  return (
    <>
      <CourseMeta course={course} sx={{ mb: 2 }} />
      <Typography>{course.description}</Typography>
    </>
  );
};

export default CourseDetails;
