import { Link } from '@mui/material';
import * as yup from 'yup';

const signUpFormFields = [
  {
    name: 'lastName',
    type: 'text',
    fieldProps: {
      label: 'Vezetéknév',
      autoFocus: true
    },
    rule: yup.string().required('Adjd meg a vezetékneved')
  },
  {
    name: 'firstName',
    type: 'text',
    fieldProps: {
      label: 'Keresztnév'
    },
    rule: yup.string().required('Add meg a keresztneved')
  },
  {
    name: 'email',
    type: 'text',
    fieldProps: {
      label: 'Email'
    },
    rule: yup.string().email('Adj meg valós email címet').required('Adj meg egy email címet')
  },
  {
    name: 'password',
    type: 'password',
    fieldProps: {
      label: 'Jelszó'
    },
    rule: yup.string().min(8, 'A jelszó legyen minimum 8 karakter').max(64, 'A jelszó legyen maximum 64 karakter')
  },
  {
    name: 'remember',
    type: 'checkbox',
    fieldProps: {
      label: (
        <>
          Az{' '}
          <Link href='https://up2you.hu/adatkezeles' target='_blank' rel='noreferrer'>
            Adatkezelési tájékoztatót
          </Link>{' '}
          és az{' '}
          <Link href='https://up2you.hu/aszf' target='_blank' rel='noreferrer'>
            ÁSZF
          </Link>
          -et megismertem és elfogadom.
        </>
      )
    },
    rule: yup.boolean().required().equals([true], 'Kérünk fogadd el a fenti dokumentumokat')
  }
];

export default signUpFormFields;
