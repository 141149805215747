import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormError from './FormError';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select as MuiSelect } from '@mui/material';
import useOptions from 'components/Options/OptionsContext/useOptions';

const SelectInput = ({ name, options, defaultValue, label, multiselect, checkDisabled, disabled, ...selectProps }) => {
  const { renderOptionList } = useOptions();
  const formContext = useFormContext();
  const labelId = `component-${label}`;
  const variant = 'outlined';
  const defaultStringValues = multiselect && defaultValue ? defaultValue.map((v) => v.toString()) : defaultValue;
  const isDisabled = checkDisabled ? checkDisabled(defaultValue) : disabled;

  return (
    <Controller
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        const stringValues = multiselect ? value.map((v) => v.toString()) : value;
        return (
          <FormError error={error}>
            <FormControl fullWidth variant={variant} error={!!error}>
              {label && (
                <InputLabel id={labelId} shrink>
                  {label}
                </InputLabel>
              )}
              <MuiSelect
                labelId={labelId}
                // native={!multiple}
                variant={variant}
                label={label}
                value={stringValues}
                onChange={onChange}
                onBlur={onBlur}
                fullWidth
                ref={ref}
                notched
                multiple={multiselect}
                renderValue={!multiselect ? null : (v) => renderOptionList(v, options)}
                disabled={isDisabled}
                {...selectProps}
              >
                {!multiselect &&
                  options &&
                  options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                {multiselect &&
                  options &&
                  options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      <Checkbox checked={value.includes(option.key)} />
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
              </MuiSelect>
            </FormControl>
          </FormError>
        );
      }}
      name={name}
      control={formContext.control}
      defaultValue={defaultStringValues || (multiselect ? [] : '')}
    />
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  multiple: PropTypes.bool,
  label: PropTypes.string
};

export default SelectInput;
