import { useState } from 'react';

import { Form } from 'ui';
import useTranslation from 'hooks/useTranslation';

import useCourseFormFields from './useCourseFormFields';
import useCourseApi from './useCourseApi';

const CourseForm = ({ onSave, onCancel, defaultValues }) => {
  const isEditMode = defaultValues?.uid;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { createCourse, updateCourse } = useCourseApi();
  const courseFormFields = useCourseFormFields(isEditMode ? 'edit' : 'new');
  const handleSubmit = async (fields) => {
    setLoading(true);
    if (isEditMode) {
      await updateCourse(defaultValues.uid, fields);
    } else {
      await createCourse(fields);
    }
    setLoading(false);

    onSave();
  };

  return (
    <Form
      fields={courseFormFields}
      onSubmit={handleSubmit}
      onAltAction={onCancel}
      submitLabel={isEditMode ? t('courses.update-course') : t('courses.create-new-course')}
      loading={loading}
      defaultValues={defaultValues}
      sx={{ mt: 2 }}
    />
  );
};

export default CourseForm;
