import { styled, Typography } from '@mui/material';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { Link } from 'ui';

const StyledCard = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderColor: theme.palette.divider,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
  opacity: 0.5,
  transition: 'opacity 0.2',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7
  },
  width: 360
}));

const CourseCardLinkToAll = () => {
  return (
    <StyledCard to='/courses' underline='none'>
      <AddCircleTwoToneIcon fontSize='large' />
      <Typography sx={{ mt: 1 }}>Tanfolyamok</Typography>
    </StyledCard>
  );
};

export default CourseCardLinkToAll;
