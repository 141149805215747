import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Button, Dialog, Form, Title } from 'ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useViewer from '../Viewer/ViewerContext/useViewer';
import useTranslation from '../../hooks/useTranslation';
import TableFilter from '../../ui/TableFilter';
import Toolbar from 'components/Layout/Toolbar';

const defaultRowsPerPage = 10;
const rowsPerPageValues = [10, 25, 50, 100];

const TablePage = ({
  title,
  titleVariant,
  titleGutterBottom,
  fields,
  idField = 'id',
  updateIdField,
  newLabel,
  deleteLabel,
  writeRole,
  onGetRows,
  onCreateRow,
  onUpdateRow,
  onDeleteRow,
  onRowClick,
  filterConfig,
  updateMessage,
  onFilterChange,
  refetchTrigger
}) => {
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState(
    filterConfig
      ? filterConfig.reduce(
          (acc, filter) => ({ ...acc, [filter[idField]]: filter.filterInitialValue || filter.filterValue }),
          {}
        )
      : null
  );
  const [rows, setRows] = useState([]);
  const [rowToEdit, setRowToEdit] = useState();
  const { viewerRoles, viewerHasRole } = useViewer();
  const { t } = useTranslation();

  const fetchRows = async () => {
    const response = await onGetRows({ ...filters, page, rowsPerPage });
    setRows(response.items);
    setCount(response.pageInfo.count);
    setLoading(false);
  };

  useEffect(() => {
    if (refetchTrigger) fetchRows();
  }, [refetchTrigger]);

  const handleSubmit = async (formFields) => {
    setFormLoading(true);
    if (rowToEdit) {
      await onUpdateRow(rowToEdit?.[updateIdField || idField], formFields);
    } else {
      await onCreateRow(formFields);
    }
    fetchRows();
    setFormLoading(false);
    setDialogOpen(false);
  };

  const handleNewClick = () => {
    setRowToEdit(null);
    setDialogOpen(true);
  };

  const handleRowClick = (item) => {
    if (parseInt(item.locked)) return;
    if (onRowClick) {
      onRowClick(item);
      return;
    }
    if (onUpdateRow || onDeleteRow) {
      setRowToEdit(item);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = async () => {
    setFormLoading(true);
    await onDeleteRow(rowToEdit?.[idField]);
    fetchRows();
    setFormLoading(false);
    setDialogOpen(false);
  };

  const handleFilterChange = (f) => {
    const newFilter = f.reduce((acc, filter) => ({ ...acc, [filter[idField]]: filter.filterValue }), {});
    setFilters(newFilter);
    onFilterChange && onFilterChange({ ...newFilter, page, rowsPerPage });
  };

  const handleChangePage = (_e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchRows();
  }, [filters, page, rowsPerPage]);

  const dialogTitle = rowToEdit ? t('common.update-item') : t('common.create-new-item');

  return (
    <Box>
      {viewerHasRole(writeRole || viewerRoles.ADMIN) && onCreateRow && (
        <Toolbar>
          <Button onClick={handleNewClick} startIcon={<AddRoundedIcon />} variant='text' size='small'>
            {newLabel || t('common.create-new-item')}
          </Button>
        </Toolbar>
      )}
      {title && <Title variant={titleVariant} title={title} gutterBottom={titleGutterBottom} />}

      {filterConfig && (
        <TableFilter title={t('courses.filter')} initialFilters={filterConfig} onFilterChange={handleFilterChange} />
      )}

      <Paper variant='outlined' sx={{ overflowX: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {fields.map((field) =>
                field.showInTable ? <TableCell key={field.name}>{field.fieldProps.label}</TableCell> : null
              )}
            </TableRow>
          </TableHead>
          {!loading && rows.length > 0 && (
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row[idField]}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: (onUpdateRow || onDeleteRow || onRowClick) && !parseInt(row.locked) ? 'pointer' : 'default'
                  }}
                  hover
                  onClick={() => handleRowClick(row)}
                >
                  {fields.map((field) =>
                    field.showInTable ? (
                      <TableCell key={`${row[idField]}-${field.name}`}>
                        {field.cellRenderer ? field.cellRenderer(row) : row[field.name]}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsPerPageValues}
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ borderTopColor: 'divider', borderTopStyle: 'solid', borderTopWidth: 1, width: '100%' }}
          labelRowsPerPage={t('table.rows-per-page')}
        />
      </Paper>

      <Dialog title={dialogTitle} isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} hideActions>
        <Form
          fields={fields}
          onSubmit={onUpdateRow || onCreateRow ? handleSubmit : null}
          onAltAction={handleCloseDialog}
          onExtraAction={onDeleteRow ? handleDelete : null}
          extraActionLabel={rowToEdit ? deleteLabel || 'Törlés' : null}
          submitLabel={dialogTitle}
          loading={isFormLoading}
          defaultValues={rowToEdit}
          message={updateMessage}
        />
      </Dialog>
    </Box>
  );
};

export default TablePage;
