import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStudentApi from './useStudentApi';
import CourseList from 'components/Course/CourseList';
import { Title } from 'ui';
import { Typography } from '@mui/material';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useTranslation from 'hooks/useTranslation';
import Illustration from 'components/Common/Illustration/Illustration';

const StudentCourses = ({ studentId }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getStudentCourses } = useStudentApi();
  const { viewerRoleIs, viewerRoles } = useViewer();
  const { t } = useTranslation();

  const fetchCourses = async () => {
    const result = await getStudentCourses(studentId);
    setCourses(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchCourses();
  }, [studentId]);

  const texts = viewerRoleIs(viewerRoles.STUDENT)
    ? {
        title: t('courses.my-courses'),
        description: t('courses.my-courses-description'),
        empty: t('courses.my-courses-empty')
      }
    : {
        title: t('courses.title'),
        description: t('courses.student-courses-description'),
        empty: t('courses.student-courses-empty')
      };

  return (
    <>
      {(!viewerRoleIs(viewerRoles.STUDENT) || courses.length > 0) && (
        <Title variant='h5' title={texts.title} gutterBottom={0} />
      )}
      {loading && (
        <Typography variant='body1' sx={{ mb: 4 }}>
          {t('courses.loading')}
        </Typography>
      )}

      {!loading && courses.length === 0 && (
        <>
          {viewerRoleIs(viewerRoles.STUDENT) ? (
            <Illustration
              type='empty'
              title={texts.empty}
              description={t('courses.my-courses-empty-description')}
              link='/courses'
              linkText={t('courses.title')}
              sx={{ mt: 6 }}
            />
          ) : (
            <Typography variant='body1' sx={{ mb: 4 }}>
              {texts.empty}
            </Typography>
          )}
        </>
      )}

      {!loading && courses.length > 0 && (
        <>
          <Typography variant='body1' sx={{ mb: 4 }}>
            {texts.description}
          </Typography>
          <CourseList
            courses={courses}
            loading={loading}
            linkToAllCourses={viewerRoleIs(viewerRoles.STUDENT)}
            columns={2}
          />
        </>
      )}
    </>
  );
};

StudentCourses.propTypes = {
  studentId: PropTypes.string
};

export default StudentCourses;
