import { useState } from 'react';
import { Stack, Hidden, Typography, Checkbox, FormControlLabel, TextField } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Dialog } from 'ui';
import Select from 'ui/Inputs/Select';
import useOptions from 'components/Options/OptionsContext/useOptions';
import useSubscriptionApi from './useSubscriptionApi';
import useTransactionApi from 'components/Transactions/useTransactionApi';

const NewSubscriptionButton = ({ studentId }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const { t } = useTranslation();
  const { viewerRoleIs, viewerRoles } = useViewer();
  const { courses, transactionTypes } = useOptions();
  const { getSubscriptionTypes } = useSubscriptionApi();
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [hours, setHours] = useState(null);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(null);
  const [createInvoice, setCreateInvoice] = useState(true);
  const [transactionType, setTransactionType] = useState('Cash');
  const [paymentAccepted, setPaymentAccepted] = useState(false);
  const { createTransaction } = useTransactionApi({});

  const price = selectedSubscriptionType?.price
    ? `${parseInt(selectedSubscriptionType.price).toLocaleString('hu')} Ft`
    : 'Válassz bérletet';

  const handleCourseChange = async (event) => {
    const courseId = event.target.value;
    const sTypes = await getSubscriptionTypes(courseId);
    setSubscriptionTypes(sTypes.map((s) => ({ key: s.uid, value: `${s.hours} órás`, price: s.price, hours: s.hours })));
  };

  const handleSubscriptionTypeChange = (event) => {
    const t = subscriptionTypes.find((s) => s.key === event.target.value);
    setHours(t.hours);
    setSelectedSubscriptionType(t);
  };

  const handleTransactionTypeChange = (event) => {
    setTransactionType(event.target.value);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSubscriptionTypes([]);
    setSelectedSubscriptionType(null);
    setPaymentAccepted(false);
    setHours(null);
    setCreateInvoice(true);
  };

  const handleSubmit = async () => {
    setFormLoading(true);
    await createTransaction({
      subscriptionTypeId: selectedSubscriptionType.key,
      studentId,
      transactionType,
      createInvoice,
      hours
    });
    handleCloseDialog();
    setFormLoading(false);
  };

  const isSubmitDisabled =
    !selectedSubscriptionType?.price || (transactionType === 'Cash' && !paymentAccepted) || isFormLoading;

  if (!viewerRoleIs(viewerRoles.ADMIN)) {
    return null;
  }
  return (
    <>
      <Button startIcon={<AddRoundedIcon />} variant='contained' onClick={() => setDialogOpen(true)}>
        <Hidden smDown>{t('courses.subscription-new')}</Hidden>
      </Button>

      <Dialog title={t('courses.subscription-new')} isOpen={isDialogOpen} onClose={handleCloseDialog} hideActions>
        <Typography variant='body1'>
          Új bérlet rögzítéséhez válasszd ki a tanfolyamot, a bérlet típusát és a fizetési módot.
        </Typography>
        <Stack direction='column' gap={3} sx={{ mt: 4 }}>
          <Select label='Tanfolyam' options={courses} fullWidth onChange={handleCourseChange} />
          <Select
            label='Típus'
            options={subscriptionTypes}
            fullWidth
            onChange={handleSubscriptionTypeChange}
            disabled={subscriptionTypes.length === 0}
          />
          <TextField
            value={hours}
            label='Óraszám'
            fullWidth
            onChange={(e) => setHours(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Select
            label='Fizetés módja'
            options={transactionTypes.filter((t) => t.key !== 'Barion')}
            fullWidth
            defaultValue='Cash'
            onChange={handleTransactionTypeChange}
          />

          <FormControlLabel
            control={
              <Checkbox checked={createInvoice} onChange={() => setCreateInvoice((previousValue) => !previousValue)} />
            }
            label='Számla kiállítása'
          />
        </Stack>

        <Stack alignItems='center' sx={{ py: 4 }}>
          <Typography variant='body2' color='text.secondary'>
            Fizetendő:
          </Typography>
          <Typography variant='h4'>{price}</Typography>
        </Stack>

        <Stack alignItems='center'>
          {transactionType === 'Cash' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentAccepted}
                  onChange={() => setPaymentAccepted((previousValue) => !previousValue)}
                />
              }
              label='A fizetendő összeget átvettem'
            />
          )}
          <Stack direction='row' alignItems='center' gap={2}>
            <Button variant='contained' color='primary' disabled={isSubmitDisabled} onClick={handleSubmit}>
              Bérlet rögzítése
            </Button>
            <Typography variant='body'>vagy</Typography>
            <Button variant='text' color='primary' onClick={handleCloseDialog}>
              Mégse
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default NewSubscriptionButton;
