import useTranslation from '../../hooks/useTranslation';
import TablePage from '../../components/Common/TablePage';
import useMyTransactionsFields from 'components/Transactions/useMyTransactionsFields';
import useTransactionApi from 'components/Transactions/useTransactionApi';

const MyTransactionsPage = () => {
  const { t } = useTranslation();
  const { getTransactions } = useTransactionApi();
  const fields = useMyTransactionsFields();

  const breadcrumbs = [{ title: t('profile.title'), path: '/my-profile' }];

  return (
    <TablePage
      title={t('profile.transactions')}
      breadcrumbs={breadcrumbs}
      fields={fields}
      onGetRows={getTransactions}
    />
  );
};

export default MyTransactionsPage;
