import Illustration from '../../components/Common/Illustration/Illustration';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useTeacherApi from '../../components/Teacher/useTeacherApi';
import { Title } from 'ui';

const TeacherProfilePage = () => {
  const [teacher, setTeacher] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getTeacher } = useTeacherApi();
  const { id } = useParams();

  const fetchTeacher = async (id) => {
    const response = await getTeacher(id);
    setTeacher(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchTeacher(id);
  }, [id]);

  return (
    <>
      {!loading && (
        <>
          <Title title={`${teacher.firstName} ${teacher.lastName}`} />
          <Illustration type='inProgress' title='hamarosan...' />
        </>
      )}
    </>
  );
};

export default TeacherProfilePage;
