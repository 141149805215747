import * as yup from 'yup';
import StatusChip from '../Common/StatusChip';
import useOptions from '../Options/OptionsContext/useOptions';

const useUserFields = () => {
  const { paymentProfiles, userStatuses, roles } = useOptions();
  return [
    {
      name: 'name',
      cellRenderer: (row) => `${row.lastName || ''} ${row.firstName || ''}`,
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'lastName',
      type: 'text',
      fieldProps: {
        label: 'Vezetéknév'
      },
      rule: yup.string().required()
    },
    {
      name: 'firstName',
      type: 'text',
      fieldProps: {
        label: 'Keresztnév'
      },
      rule: yup.string().required()
    },
    {
      name: 'email',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Email'
      },
      rule: yup.string().email().required()
    },
    {
      name: 'paymentProfile',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => paymentProfiles.find((item) => item.key === row.paymentProfile)?.value || '',
      fieldProps: {
        label: 'Számlázási információ',
        options: paymentProfiles
      },
      rule: yup.string()
    },
    {
      name: 'role',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => roles.find((item) => item.key === row.role)?.value || '',
      fieldProps: {
        label: 'Role',
        options: roles
      },
      rule: yup.string().required()
    },
    {
      name: 'status',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='user' />,
      fieldProps: {
        label: 'Státusz',
        options: userStatuses
      },
      rule: yup.string().required()
    }
  ];
};

export default useUserFields;
