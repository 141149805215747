import { Outlet } from 'react-router-dom';
import Layout from 'components/Layout/Layout';

const ProtectedRoutes = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default ProtectedRoutes;
