import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useTransactionApi = ({ courseId, studentId }) => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getTransactions = async (filters) => {
    try {
      if (courseId) filters.course = courseId;
      if (studentId) filters.student = studentId;
      const { data } = await api({
        url: `/transaction${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createTransaction = async (transaction) => {
    try {
      const { data } = await api({
        url: '/transaction',
        method: 'post',
        data: transaction
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getTransactions,
    createTransaction
  };
};

export default useTransactionApi;
