import { Link } from '@mui/material';
import useOptions from '../Options/OptionsContext/useOptions';

const useMyTransactionsFields = () => {
  const { courses } = useOptions();
  return [
    {
      name: 'createdOn',
      showInTable: true,
      fieldProps: {
        label: 'Dátum'
      }
    },
    {
      name: 'courseId',
      showInTable: true,
      cellRenderer: (row) => courses.find((item) => item.key === row.courseId)?.value || '',
      fieldProps: {
        label: 'Tanfolyam'
      }
    },
    {
      name: 'price',
      showInTable: true,
      cellRenderer: (row) => parseInt(row.price).toLocaleString('hu'),
      fieldProps: {
        align: 'right',
        label: 'Ár'
      }
    },
    {
      name: 'quantity',
      showInTable: true,
      fieldProps: {
        label: 'Mennyiség'
      }
    },
    {
      name: 'invoiceUrl',
      showInTable: true,
      cellRenderer: (row) =>
        row.invoiceUrl ? (
          <Link href={`${process.env.REACT_APP_API_BASE_URL}download/${row.invoiceUrl}`}>Letöltés</Link>
        ) : (
          '-'
        ),
      fieldProps: {
        label: 'Számla'
      }
    }
  ];
};

export default useMyTransactionsFields;
