import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from 'config';
import useTranslation from '../../hooks/useTranslation';
import { IconButton, Tooltip, Stack } from '@mui/material';
import useViewer from '../Viewer/ViewerContext/useViewer';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const sxListItemButton = {
  borderRadius: 1,
  transition: 'all 0.2s ease-in-out',
  '& svg': {
    transition: 'color 0.2s ease-in-out',
    opacity: 0.6,
    color: '#FFF'
  },
  ':hover': {
    backgroundColor: 'rgba(255,255,255,0.07)'
  }
};

const sxActiveIcon = {
  '& svg': {
    opacity: 1,
    color: '#FFF !important'
  }
};

// const sxActive = {
//   backgroundColor: 'primary.main',
//   '&:hover': {
//     backgroundColor: 'primary.main'
//   },
//   '& span': {
//     color: '#FFF'
//   }
// };

// const sxSubMenu = {
//   backgroundColor: 'rgba(0,0,0,0.02)',
//   mx: 2,
//   borderRadius: 1,
//   flexGrow: { xs: 1, md: 0 },
//   width: { xs: '100%', md: 'auto' },
//   justifyContent: { xs: 'space-evenly', md: 'center' }
// };

const Navigation = () => {
  const location = useLocation();
  const [opened, setOpened] = useState();
  const { t } = useTranslation();
  const { viewerHasRole } = useViewer();

  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));

  const toggleMenuOpen = (id) => {
    setOpened(id === opened ? null : id);
  };

  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      gap={{ xs: 0, md: 2 }}
      sx={{
        alignItems: 'center',
        width: '100%',
        justifyContent: { xs: 'space-evenly', md: 'center' },
        px: { xs: 3, md: 0 }
      }}
    >
      {config.navigation.map((item) => {
        const isActive = item.path === '/' ? location.pathname === item.path : location.pathname.includes(item.path);
        return (
          <React.Fragment key={item.id}>
            {item.path && !item.items && (!item.role || viewerHasRole(item.role)) && !opened && (
              <Tooltip title={t(item.title)} placement={isScreenMd ? 'right' : 'top'} arrow>
                <IconButton
                  component={Link}
                  to={item.path}
                  sx={{ ...sxListItemButton, ...(isActive ? sxActiveIcon : {}) }}
                  disableRipple
                >
                  {item.icon}
                </IconButton>
              </Tooltip>
              // <ListItem disablePadding key={item.path} sx={{ px: 2 }}>
              //   <ListItemButton
              //     component={Link}
              //     to={item.path}
              //     sx={{ ...sxListItemButton, ...(isActive ? sxActive : {}) }}
              //   >
              //     <ListItemIcon sx={{ ...(isActive ? sxActiveIcon : {}) }}>{item.icon}</ListItemIcon>
              //     <ListItemText sx={sxListItemText} primary={t(item.title)} />
              //   </ListItemButton>
              // </ListItem>
            )}

            {item.items && (!item.role || viewerHasRole(item.role)) && (
              <>
                <Tooltip title={t(item.title)} placement={isScreenMd ? 'right' : 'top'} arrow>
                  <IconButton
                    onClick={() => toggleMenuOpen(item.id)}
                    sx={{ ...sxListItemButton, ...(isActive ? sxActiveIcon : {}) }}
                    disableRipple
                  >
                    {opened === item.id ? <ChevronLeftRoundedIcon /> : item.icon}
                  </IconButton>
                </Tooltip>
                {/* <ListItem disablePadding key={item.path} sx={{ px: 2 }}>
                  <ListItemButton onClick={() => toggleMenuOpen(item.id)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText sx={sxListItemText} primary={t(item.title)} />
                    {opened === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                </ListItem> */}

                {opened === item.id &&
                  item.items.map((subItem) => {
                    const isSubItemActive = location.pathname.includes(subItem.path);
                    return (
                      <Tooltip key={subItem.id} title={t(subItem.title)} placement={isScreenMd ? 'right' : 'top'} arrow>
                        <IconButton
                          component={Link}
                          to={subItem.path}
                          sx={{ ...sxListItemButton, ...(isSubItemActive ? sxActiveIcon : {}) }}
                          disableRipple
                        >
                          {subItem.icon}
                        </IconButton>
                      </Tooltip>
                    );
                  })}
              </>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default Navigation;
