import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useCourseApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getSubscriptions = async (filters) => {
    try {
      const { data } = await api({
        url: `/admin/subscription${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getSubscriptionTypes = async (courseId) => {
    try {
      const { data } = await api({
        url: `/courses/${courseId}/subscription-types`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createSubscriptionTypes = async (courseId, subscription) => {
    try {
      const { data } = await api({
        url: `/courses/${courseId}/subscription-types`,
        data: subscription,
        method: 'post'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateSubscription = async (subscriptionId, subscription) => {
    try {
      const { data } = await api({
        url: `/admin/subscription/${subscriptionId}`,
        data: {
          ...subscription,
          start: subscription.start || null,
          end: subscription.end || null,
          hours: subscription.hours || null
        },
        method: 'put'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const processSubscription = async (transactionId) => {
    try {
      const { data } = await api({
        url: `/subscription/process?transactionId=${transactionId}`,
        method: 'post'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deleteSubscriptionTypes = async (courseId, uid) => {
    try {
      const { data } = await api({
        url: `/courses/${courseId}/subscription-types/${uid}`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getSubscriptions,
    getSubscriptionTypes,
    createSubscriptionTypes,
    updateSubscription,
    deleteSubscriptionTypes,
    processSubscription
  };
};

export default useCourseApi;
