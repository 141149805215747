import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useLanguagesApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getLanguages = async (filters) => {
    try {
      const { data } = await api({
        url: `/settings/languages${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createLanguage = async (fields) => {
    try {
      const { data } = await api({
        url: '/settings/languages',
        method: 'post',
        data: fields
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateLanguage = async (id, fields) => {
    try {
      const { data } = await api({
        url: `/settings/languages/${id}`,
        method: 'put',
        data: fields
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deleteLanguage = async (id) => {
    try {
      const { data } = await api({
        url: `/settings/languages/${id}`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return { getLanguages, createLanguage, updateLanguage, deleteLanguage };
};

export default useLanguagesApi;
