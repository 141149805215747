import { Box, styled } from '@mui/material';

const TableContainer = styled(Box)(({ theme }) => ({
  '.MuiPaper-root': {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden'
  },
  '.MuiTableHead-root th': {
    borderBottom: 'none'
  },
  '.MuiFormControl-root': {
    width: '100%'
  },
  '.MuiTableBody-root .MuiTableRow-root': {
    '&:hover': {
      '& td': {
        background: theme.palette.grey[100]
      }
    },
    '&#m--table--filter--row': {
      '&:hover': {
        '& td': {
          background: 'none'
        }
      }
    }
  },
  '#m--table--filter--row td': {
    paddingTop: 0,
    paddingBottom: 12,

    '& .MuiInput-underline': {
      background: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      '&:hover, &.Mui-focused': {
        background: theme.palette.grey[200]
      },
      '&:before': {
        border: 'none'
      },
      '&:after': {
        border: 'none'
      }
    }
  },
  '.MuiOutlinedInput-root': {
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    paddingLeft: 0,
    '&:hover, &.Mui-focused': {
      background: theme.palette.grey[200]
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none'
    },
    '.MuiOutlinedInput-input': {
      padding: 5
    }
  },
  '.MuiTableCell-footer': {
    border: 'none'
  }
}));

export default TableContainer;
