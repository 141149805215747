import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import useOptions from '../Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';

const useUserFilters = () => {
  const { roles, userStatuses } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'role',
      label: 'Szerep',
      filterInitialValue: searchParams.get('role') || '',
      filterValue: '',
      filterOptions: roles,
      filterType: 'select',
      icon: AdminPanelSettingsRoundedIcon
    },
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: userStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useUserFilters;
