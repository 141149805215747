import * as yup from 'yup';
import useOptions from '../Options/OptionsContext/useOptions';

const useEventFormFields = () => {
  const { teachers, courseLocationTypes } = useOptions();
  return [
    {
      name: 'capacity',
      type: 'number',
      fieldProps: {
        label: 'Kapacitás'
      },
      rule: yup.number().required()
    },
    {
      name: 'teacher_ids',
      type: 'select',
      fieldProps: {
        label: 'Tanár',
        options: teachers,
        multiselect: true
      },
      rule: yup.array().test({
        message: 'Legalább egy tanárt ki kell választani!',
        test: (arr) => arr.length > 0
      })
    },
    {
      name: 'courseEventLocationType',
      type: 'select',
      fieldProps: {
        label: 'Helyszín típusa',
        options: courseLocationTypes
      }
    },
    {
      name: 'courseEventOnlineLocation',
      type: 'text',
      fieldProps: {
        label: 'Online helyszín'
      },
      rule: yup.string()
    },
    {
      name: 'courseEventPersonalLocation',
      type: 'text',
      fieldProps: {
        label: 'Személyes helyszín'
      },
      rule: yup.string()
    }
  ];
};

export default useEventFormFields;
