import * as yup from 'yup';
import StatusChip from '../Common/StatusChip';

const useLanguagesFields = () => {
  return [
    {
      name: 'name',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Név'
      },
      rule: yup.string().required()
    },
    {
      name: 'code',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Kód'
      },
      rule: yup.string().required()
    },
    {
      name: 'status',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='language' />,
      fieldProps: {
        label: 'Státusz',
        options: [
          { key: 'active', value: 'Aktív' },
          { key: 'inactive', value: 'Inaktív' }
        ]
      },
      rule: yup.string().required()
    }
  ];
};

export default useLanguagesFields;
