import * as yup from 'yup';
import StatusChip from '../Common/StatusChip';
import useOptions from '../Options/OptionsContext/useOptions';

const useStudentFields = () => {
  const { userStatuses, paymentProfiles } = useOptions();
  return [
    {
      name: 'name',
      cellRenderer: (row) => `${row.lastName || ''} ${row.firstName || ''}`,
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'email',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Email'
      },
      rule: yup.string().email().required()
    },
    {
      name: 'phone',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Telefon'
      },
      rule: yup.string().required()
    },
    {
      name: 'paymentProfile',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => paymentProfiles.find((item) => item.key === row.paymentProfile)?.value || '',
      fieldProps: {
        label: 'Számlázási információ',
        options: paymentProfiles
      },
      rule: yup.string()
    },
    {
      name: 'status',
      type: 'select',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='user' />,
      fieldProps: {
        label: 'Státusz',
        options: userStatuses
      },
      rule: yup.string().required()
    }
  ];
};

export default useStudentFields;
