import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useTeacherApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getTeachers = async (filters) => {
    try {
      const { data } = await api({
        url: `/teacher${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getTeacher = async (id) => {
    try {
      const { data } = await api({
        url: `/teacher/${id}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getTeachers,
    getTeacher
  };
};

export default useTeacherApi;
