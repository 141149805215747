import React, { useCallback, useState } from 'react';
import Calendar from 'ui/Calendar/Calendar';
import useEventApi from 'components/Event/useEventApi';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import { styled } from '@mui/system';
import { DATE_FORMATS, formatDate, localIsoStringToUtcTimestamp } from 'utils/date';
import { Dialog } from 'ui';
import { Box } from '@mui/material';
import EventForm from '../Event/EventForm';
import EntryDetails from 'components/Entry/EntryDetails';
import useViewer from '../Viewer/ViewerContext/useViewer';
import EntryBookConfirmationDialog from '../Entry/EntryBookConfirmationDialog';
import useEntryApi from '../Entry/useEntryApi';
import useOptions from '../Options/OptionsContext/useOptions';
import { useDialog } from '../Common/DialogContext/DialogContext';
import { useCourseContext } from './CourseContext';
import { icons } from './CourseMeta';

const EventBgMetaItem = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  opacity: 0.75,
  fontSize: '0.75rem',
  '& svg': {
    fontSize: '0.875rem',
    opacity: 0.75
  }
});

const EventMetaItem = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '0.5rem',
  opacity: 0.75,
  fontSize: '0.75rem',
  '& svg': {
    fontSize: '0.875rem',
    opacity: 0.75
  }
});

const EventMetaTeachers = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  opacity: 0.75,
  fontSize: '0.75rem',
  '& svg': {
    fontSize: '0.875rem',
    opacity: 0.75
  }
});

const getEntryIcon = (locked) => {
  return locked ? <LockRoundedIcon fontSize='small' /> : <ScheduleRoundedIcon fontSize='small' />;
};

const CourseCalendar = ({ onSubscribe }) => {
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(false);
  const { getEvents, updateEvent, copyWeek } = useEventApi();
  const { createEntry, updateEntry, deleteEntry } = useEntryApi();
  const { viewerRoleIs, viewerRoles } = useViewer();
  const calendarRef = React.createRef();
  const { renderOptionList, teachers, getOptionValue } = useOptions();
  const { openDialog, closeDialog } = useDialog();
  const [isCopyDialogOpen, setCopyDialogOpen] = useState(false);
  const { course, refetchSubscriptions } = useCourseContext();

  const renderEventContent = useCallback((args) => {
    if (args.event.display === 'background') {
      return (
        <Box sx={{ p: 1 }}>
          <EventBgMetaItem>
            <Box sx={{ whiteSpace: 'no-wrap', mr: 0.5, fontWeight: 700 }}>
              {formatDate(args.event.startStr, DATE_FORMATS.time)} - {formatDate(args.event.endStr, DATE_FORMATS.time)}
            </Box>{' '}
            (
            {args.event.extendedProps.courseEventLocationType
              ? getOptionValue('courseLocationTypes', args.event.extendedProps.courseEventLocationType)
              : getOptionValue('courseLocationTypes', args.event.extendedProps.courseLocationType)}
            )
          </EventBgMetaItem>
          <EventBgMetaItem>{renderOptionList(args.event.extendedProps.teacher_ids, teachers)}</EventBgMetaItem>
        </Box>
      );
    }
    return (
      <>
        <EventMetaItem>
          {getEntryIcon(args.event.extendedProps.locked)}
          {formatDate(args.event.startStr, DATE_FORMATS.time)} - {formatDate(args.event.endStr, DATE_FORMATS.time)}
        </EventMetaItem>
        {!viewerRoleIs(viewerRoles.STUDENT) && (
          <>
            <EventMetaItem>
              <GroupsRoundedIcon fontSize='small' /> {args.event.extendedProps.entries} /{' '}
              {args.event.extendedProps.capacity}
            </EventMetaItem>
            <EventMetaItem>
              {icons[args.event.extendedProps.courseEventLocationType]}
              {getOptionValue('courseLocationTypes', args.event.extendedProps.courseEventLocationType)}
            </EventMetaItem>
            <EventMetaTeachers>{renderOptionList(args.event.extendedProps.teacher_ids, teachers)}</EventMetaTeachers>
          </>
        )}
      </>
    );
  }, []);

  const fetchEvents = async (info) => {
    const filters = {
      start: localIsoStringToUtcTimestamp(info.startStr),
      end: localIsoStringToUtcTimestamp(info.endStr)
    };
    return await getEvents(course.uid, filters);
  };

  const handleDeleteEntry = async (entryId) => {
    await deleteEntry(entryId);
    refetchSubscriptions();
  };

  const handleDateSelect = (selectInfo) => {
    if (viewerRoleIs(viewerRoles.STUDENT)) {
      const backgroundEvent = getOverlappingBackgroundEvents(selectInfo);
      const isMixed = backgroundEvent?.extendedProps?.courseEventLocationType
        ? backgroundEvent?.extendedProps?.courseEventLocationType === 'mixed'
        : backgroundEvent?.extendedProps?.courseLocationType === 'mixed';
      setSelectedEntry({ ...selectInfo, isMixed });
      return;
    }
    setSelectedEvent(selectInfo);
  };

  const handleUpdateEvent = async ({ event }) => {
    if (viewerRoleIs(viewerRoles.STUDENT)) {
      await updateEntry(event.id, {
        start: localIsoStringToUtcTimestamp(event.startStr),
        end: localIsoStringToUtcTimestamp(event.endStr),
        course_id: course.uid
      });

      const calendarApi = calendarRef.current.getApi();
      calendarApi.refetchEvents();
      refetchSubscriptions();
      return;
    }

    await updateEvent(event.id, {
      start: localIsoStringToUtcTimestamp(event.startStr),
      end: localIsoStringToUtcTimestamp(event.endStr)
    });
    refetchSubscriptions();
  };

  const handleCloseDialog = () => {
    setSelectedEvent(false);
  };

  const handleSelectEvent = (eventClickInfo) => {
    if (
      (viewerRoleIs(viewerRoles.STUDENT) || viewerRoleIs(viewerRoles.TEACHER)) &&
      (eventClickInfo?.event?.extendedProps?.locked || eventClickInfo?.event?.display === 'background')
    ) {
      return;
    }
    setSelectedEvent(eventClickInfo);
  };

  const handleCloseEntryDialog = () => {
    setSelectedEntry(false);
  };

  const getOverlappingBackgroundEvents = (entry) => {
    const calendarApi = calendarRef.current.getApi();
    const events = calendarApi.getEvents();

    const overlappingEvent = events.find((event) => {
      if (event.display !== 'background') {
        return false;
      }

      return (
        localIsoStringToUtcTimestamp(event.startStr) <= localIsoStringToUtcTimestamp(entry.startStr) &&
        localIsoStringToUtcTimestamp(event.endStr) >= localIsoStringToUtcTimestamp(entry.endStr)
      );
    });

    return overlappingEvent;
  };

  const handleSaveEntry = async (location = null) => {
    await createEntry({
      start: localIsoStringToUtcTimestamp(selectedEntry.startStr),
      end: localIsoStringToUtcTimestamp(selectedEntry.endStr),
      course_id: course.uid,
      location_type: location
    });
    refetchSubscriptions();
    handleCloseEntryDialog();
  };

  const enableDateSelect = viewerRoleIs(viewerRoles.ADMIN) || viewerRoleIs(viewerRoles.STUDENT);

  const handleSubscribe = () => {
    closeDialog();
    onSubscribe();
  };

  const openAlertDialog = () => {
    if (viewerRoleIs(viewerRoles.STUDENT)) {
      openDialog({
        title: 'Még nincs bérleted',
        text: 'Erre a tanfolyamra nincs aktív bérleted. Ha szeretnél részt venni, akkor kattints a "Jelentkezés" gombra. És válassz egy bérletet.',
        acceptText: 'Jelentkezés',
        onConfirm: handleSubscribe
      });
    }
  };

  const checkSelectable = (selectInfo) => {
    const now = localIsoStringToUtcTimestamp(new Date());
    const start = localIsoStringToUtcTimestamp(selectInfo.startStr);
    const end = localIsoStringToUtcTimestamp(selectInfo.endStr);
    return now < start && now < end;
  };

  const handleCopyWeek = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.view.activeStart;
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7) + 1;

    copyWeek(course.uid, currentDate.getFullYear(), weekNumber);
    setCopyDialogOpen(false);
  };

  return (
    <>
      <Calendar
        onClick={() => (enableDateSelect ? null : openAlertDialog())}
        calendarRef={calendarRef}
        onDateSelect={enableDateSelect ? handleDateSelect : null}
        selectable={enableDateSelect}
        selectMirror
        events={fetchEvents}
        eventChange={handleUpdateEvent}
        eventContent={renderEventContent}
        eventClick={handleSelectEvent}
        constraintGroupId={viewerRoleIs(viewerRoles.STUDENT) ? 'event' : null}
        selectAllow={checkSelectable}
        selectOverlap={(event) => {
          return event.display === 'background';
        }}
        eventOverlap={(stillEvent) => {
          return stillEvent.display === 'background';
        }}
        headerToolbar={{
          start: '',
          center: '',
          end: `title prev,next today${viewerRoleIs(viewerRoles.ADMIN) ? ' copyWeekButton' : ''}`
        }}
        customButtons={{
          copyWeekButton: {
            text: 'Hét másolása',
            click: () => setCopyDialogOpen(true)
          }
        }}
      />
      <Dialog title={course.name} isOpen={selectedEvent} onClose={handleCloseDialog} hideActions>
        {viewerRoleIs(viewerRoles.ADMIN) ? (
          <EventForm
            course={course}
            onCancel={handleCloseDialog}
            onSave={handleCloseDialog}
            selectedEvent={selectedEvent}
          />
        ) : (
          <EntryDetails selectedEntry={selectedEvent} onClose={handleCloseDialog} onDelete={handleDeleteEntry} />
        )}
      </Dialog>

      <Dialog
        title='Hét másolása'
        isOpen={isCopyDialogOpen}
        onClose={() => setCopyDialogOpen(false)}
        onConfirm={handleCopyWeek}
      >
        <Box>A hét másolásával az aktuális hét eseményei másolódnak a következő hétre.</Box>
      </Dialog>

      <EntryBookConfirmationDialog
        entry={selectedEntry}
        isOpen={selectedEntry}
        onConfirm={handleSaveEntry}
        onClose={handleCloseEntryDialog}
      />
    </>
  );
};

export default CourseCalendar;
