import { useEffect, useState } from 'react';
import StudentCourses from 'components/Student/StudentCourses';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useDashboardApi from 'components/Dashboard/useDashboardApi';
import Grid from '@mui/material/Unstable_Grid2';

import UpcomingEvents from 'components/Event/UpcomingEvents';

const DashboardPage = () => {
  const { viewer, viewerRoleIs, viewerRoles } = useViewer();
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState(null);
  const { getDashboard } = useDashboardApi();

  const fetchDashboard = async () => {
    const response = await getDashboard();
    setDashboard(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {dashboard?.events?.length > 0 && (
          <Grid xs={12}>{loading ? <div>Loading...</div> : <UpcomingEvents events={dashboard.events} />}</Grid>
        )}
        {viewerRoleIs(viewerRoles.STUDENT) && (
          <Grid xs={12} sx={{ mt: 4 }}>
            <StudentCourses studentId={viewer.id} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DashboardPage;
