import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCourseApi from 'components/Course/useCourseApi';

const CourseContext = React.createContext({});

export const CourseContextProvider = ({ children }) => {
  const [course, setCourse] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const { getCourse } = useCourseApi();

  const fetchCourse = async (uid) => {
    const response = await getCourse(uid);
    setCourse(response['course']);
    setSubscriptions(response['subscriptions']);
    setActiveSubscription(response['activeSubscription']);
    setLoading(false);
  };

  const fetchSubscriptions = async (uid) => {
    console.log('refetching subscriptions');
    const response = await getCourse(uid);
    setSubscriptions(response['subscriptions']);
    setActiveSubscription(response['activeSubscription']);
  };

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  const value = {
    course,
    subscriptions,
    activeSubscription,
    loading,
    refetchCourse: () => fetchCourse(id),
    refetchSubscriptions: () => fetchSubscriptions(id)
  };

  return <CourseContext.Provider value={value}>{children}</CourseContext.Provider>;
};

export const useCourseContext = () => useContext(CourseContext);
