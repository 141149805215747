import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import shadows from './shadows';
import { huHU } from '@mui/material/locale';

const palette = {
  primary: {
    main: '#f39c12',
    light: '#fff6e9',
    contrastText: '#FFF'
  },
  secondary: {
    main: '#112a44'
  },
  text: {
    primary: '#112a44',
    secondary: '#777e91'
  },
  background: {
    dark: '#112a44',
    paper: '#FFFFFF',
    default: '#f9fafc',
    secondary: '#F1F1F1'
  },
  error: {
    main: '#E2340D'
  },
  success: {
    main: '#97c66a'
  },
  divider: '#eaeaea;',
  dividerDark: '#1A395D'
};

let theme = createTheme({
  huHU,
  palette,
  shadows,
  shape: {
    borderRadius: 7
  },
  typography: {
    fontFamily: '"Lexend", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h3: {
      fontWeight: 700
    },
    h4: {
      fontWeight: 700
    },
    h5: {
      fontWeight: 700
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflow: 'hidden',
          height: '100%'
        },
        body: {
          height: '100%',
          overflow: 'auto',
          overscrollBehaviorY: 'none'
        },
        '::-moz-selection': {
          color: 'white',
          background: '#e8ae00'
        },
        '::selection': {
          color: 'white',
          background: '#e8ae00'
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          fontWeight: 700,
          textTransform: 'none'
        },
        sizeSmall: {
          height: '1.8rem',
          fontSize: '0.75rem'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 300,
          textTransform: 'uppercase',
          color: '#777e91'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '.MuiListItemSecondaryAction-root': {
            display: 'none'
          },
          '&:hover': {
            '.MuiListItemSecondaryAction-root': {
              display: 'block'
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottomStyle: 'none',
          minHeight: '1rem'
        },
        flexContainer: {
          gap: '2rem'
        },
        indicator: {
          height: '0.25rem',
          borderRadius: '0.25rem'
        }
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          padding: '0.5rem 0',
          fontSize: '1rem',
          minWidth: 0,
          fontWeight: 700,
          textTransform: 'capitalize',
          color: palette.text.primary
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // button: {
          //   visibility: 'hidden'
          // },
          // ':hover': {
          //   button: {
          //     visibility: 'visible'
          //   }
          // },
          '.MuiToolbar-root': {
            button: {
              visibility: 'visible'
            }
          },
          '.MuiTableCell-root': {
            borderBottom: `1px solid ${palette.divider}`
          },
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: palette.primary.light
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 12
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 10
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: palette.divider
        }
      },
      defaultProps: {
        elevation: 0
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 8
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${palette.divider}`,
          padding: '8px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.background.dark
        },
        arrow: {
          color: palette.background.dark
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.2)'
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: 7
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
