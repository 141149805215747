import { useState } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { Button } from 'ui';

const ConfirmButton = ({ onConfirm, title, color, ...props }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = (e) => {
    e.stopPropagation();
    onConfirm();
    setOpen(false);
  };

  return (
    <>
      {!open && (
        <Button onClick={handleOpen} color={color} {...props}>
          {title}
        </Button>
      )}
      {open && (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography color={color} variant='body2' sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
            {t('common.are-you-sure')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseTwoToneIcon />
          </IconButton>
          <IconButton onClick={handleConfirm} color={color}>
            <CheckTwoToneIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ConfirmButton;
