export const readFileAsync = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e.target.result;
      resolve(result);
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsDataURL(file);
  });

export const blobToBase64Async = (blob) =>
  new Promise((resolve) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(blob);
  });

export const getFileMeta = (file) => {
  if (!file) {
    return null;
  }

  const chunkSize = 500 * 1024;
  const length = file.size;
  const mimeType = file.type;
  const totalParts = Math.ceil(length / chunkSize);

  return {
    totalParts,
    chunkSize,
    mimeType
  };
};
