import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import Form from 'ui/Form/Form';
import PageLoader from 'components/PageLoader/PageLoader';

const yupRuleMap = {
  text: yup.string(),
  textarea: yup.string(),
  select: yup.string(),
  number: yup.string(),
  date: yup.string()
};

const getYupRule = (field) => {
  const rule = yupRuleMap[field.fieldtype] || null;
  return field.required && field.required !== '0' && rule ? rule.required('Ez a mező kötelező') : rule;
};

const mapFormFields = (fields) =>
  fields.reduce(
    (acc, field) => [
      ...acc,
      ...(field.fieldtype
        ? [
            {
              name: field.field,
              type: field.fieldtype,
              fieldProps: {
                label: field.label,
                options: field.valueset,
                url: field.url
              },
              rule: getYupRule(field)
            }
          ]
        : [])
    ],
    []
  );

const DetailsForm = ({ formSchema, onCancel, onSave }) => {
  const [formLoading, setFormLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formFields, setFormFields] = useState();
  const [defaultData, setDefaultData] = useState();

  const getFormSchema = async () => {
    if (Array.isArray(formSchema)) {
      setFormFields(mapFormFields(formSchema));
      setFormLoading(false);
    } else {
      try {
        const result = await formSchema();
        setFormFields(mapFormFields(result.editsheet));
        setDefaultData(result.putsheet);
        setFormLoading(false);
      } catch (error) {
        setFormLoading(false);
      }
    }
  };

  useEffect(() => {
    getFormSchema();
  }, []);

  const onSubmit = async (formFields) => {
    setSubmitLoading(true);
    await onSave(formFields);
    onCancel();
  };

  const args = {
    fields: formFields,
    onSubmit: onSubmit,
    onAltAction: onCancel,
    submitLabel: 'Mentés',
    defaultValues: defaultData
  };

  return (
    <>
      {formLoading && <PageLoader />}
      {!formLoading && <Form {...args} loading={submitLoading} />}
    </>
  );
};

export default DetailsForm;
