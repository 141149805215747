import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

const CheckboxFilter = ({ label, handleCheckboxChange, id, checked }) => {
  const handleChange = (event) => {
    handleCheckboxChange(id, event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox color='primary' onChange={handleChange} checked={checked} />}
      label={
        <Typography color='secondary' sx={{ fontWeight: '700' }}>
          {label}
        </Typography>
      }
    />
  );
};

CheckboxFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  handleCheckboxChange: PropTypes.func
};

export default CheckboxFilter;
