import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';
import { utcTimestampToLocalIsoString } from 'utils/date';

const useEventApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getEvents = async (courseId, filters) => {
    try {
      const { data } = await api({
        url: `/events/${courseId}${createFilterParams(filters)}`,
        method: 'get'
      });
      const convertedData = data.map((event) => ({
        ...event,
        start: utcTimestampToLocalIsoString(event.start),
        end: utcTimestampToLocalIsoString(event.end),
        editable: !event.locked
      }));
      return convertedData;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createEvent = async (event) => {
    try {
      const { data } = await api({
        url: '/events',
        method: 'post',
        data: event
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateEvent = async (id, event) => {
    try {
      const { data } = await api({
        url: `/events/${id}`,
        method: 'put',
        data: event
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      const { data } = await api({
        url: `/events/${id}`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const copyWeek = async (course_id, year, source_week) => {
    try {
      const { data } = await api({
        url: '/events/copy',
        method: 'post',
        data: { course_id, year, source_week, weeks: 1 }
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    copyWeek
  };
};

export default useEventApi;
