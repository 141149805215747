import useApi from 'api/useApi';
import { useError } from 'hooks/useError';

const useStudentCoursesApi = (id) => {
  const { api } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getStudentCourses = async () => {
    try {
      const { data } = await api({
        url: `/student/${id}/courses-list`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateStudentCourse = async (courseId, course) => {
    const payload = {
      ...course,
      courseId
    };
    try {
      const { data } = await api({
        url: `/student/${id}/courses-list`,
        method: 'put',
        data: payload
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getStudentCourses,
    updateStudentCourse
  };
};

export default useStudentCoursesApi;
