import LanguageIcon from '@mui/icons-material/Language';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import useOptions from '../Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

const useUserFilters = () => {
  const { languages, courseStatuses, courseGroupTypes, courseLocationTypes } = useOptions();
  const { viewerRoleIs, viewerRoles } = useViewer();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'language',
      label: 'Nyelv',
      filterInitialValue: searchParams.get('language') || '',
      filterValue: '',
      filterOptions: languages,
      filterType: 'select',
      icon: LanguageIcon
    },
    {
      id: 'groupType',
      label: 'Csoport létszám',
      filterInitialValue: searchParams.get('groupType') || '',
      filterValue: '',
      filterOptions: courseGroupTypes,
      filterType: 'select',
      icon: Groups2RoundedIcon
    },
    {
      id: 'locationType',
      label: 'Helyszín',
      filterInitialValue: searchParams.get('loactionType') || '',
      filterValue: '',
      filterOptions: courseLocationTypes,
      filterType: 'select',
      icon: BusinessRoundedIcon
    },
    ...(!viewerRoleIs(viewerRoles.STUDENT)
      ? [
          {
            id: 'status',
            label: 'Státusz',
            filterInitialValue: searchParams.get('status') || '',
            filterValue: '',
            filterOptions: courseStatuses,
            filterType: 'select',
            icon: CheckCircleOutlineRoundedIcon
          }
        ]
      : [])
  ];
};

export default useUserFilters;
