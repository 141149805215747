import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Form, Link } from 'ui';
import { useNavigate } from 'react-router-dom';

import useViewer from 'components/Viewer/ViewerContext/useViewer';

import loginFormFields from './loginFormFields';
import AuthPageLayout from './AuthPageLayout';

export default function LoginPage() {
  const { viewer, login } = useViewer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (viewer) {
      navigate('/');
    }
  }, []);

  const handleLogin = async (fields) => {
    setLoading(true);
    const result = await login(fields);
    if (!result) {
      setLoading(false);
    }
  };

  return (
    <AuthPageLayout title='Üdvözlünk újra nálunk' description='Add meg az alábbi adatokat a belépéshez'>
      <Form fields={loginFormFields} onSubmit={handleLogin} submitLabel='Belépés' fullWidthSubmit loading={loading} />

      <Typography variant='body2' sx={{ mt: 4, textAlign: 'center' }}>
        Ha még nincs Up2You fiókod, akkor <Link to='/signup'>regisztrálj!</Link>
      </Typography>
      <Typography variant='body2' sx={{ mt: 1, textAlign: 'center' }}>
        <Link to='/reset-password'>Elfelejtetted a jelszavad?</Link>
      </Typography>
    </AuthPageLayout>
  );
}
