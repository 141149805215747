import React from 'react';
import { CircularProgress, Button as MuiButton } from '@mui/material';

const Button = ({ children, loading, color, variant, ...rest }) => {
  return (
    <MuiButton disableElevation color={color || 'primary'} variant={variant || 'contained'} {...rest}>
      {loading && <CircularProgress sx={{ marginRight: 1 }} color='inherit' size={20} />}
      {children}
    </MuiButton>
  );
};

export default Button;
