import { useMemo, useState } from 'react';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Avatar, Stack } from '@mui/material';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { useNavigate } from 'react-router-dom';
import { getInitials } from 'utils/text';

const CurrentUser = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, viewer } = useViewer();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const handleProfileClick = () => {
    handleClose();
    navigate('/my-profile');
  };

  const name = viewer.firstName && viewer.lastName ? `${viewer.lastName} ${viewer.firstName}` : viewer.name;

  const avatar = useMemo(
    () => (
      <Avatar sx={{ width: 40, height: 40, bgcolor: 'primary.light', color: 'text.primary', fontSize: '1rem' }}>
        {getInitials(name)}
      </Avatar>
    ),
    [name]
  );

  return (
    <>
      <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick} disableRipple sx={{ p: 0 }}>
        {avatar}
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={10}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: 'default', '&:hover': { backgroundColor: 'transparent' } }}
        >
          <Stack direction='row' gap={1} alignItems='center'>
            {avatar}
            {name}
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <AccountCircleTwoToneIcon color='primary' />
          </ListItemIcon>
          <ListItemText>Profil</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutTwoToneIcon color='primary' />
          </ListItemIcon>
          <ListItemText>Kilépés</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CurrentUser;
