import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useCourseApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getCourses = async (filters) => {
    try {
      const { data } = await api({
        url: `/courses${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getCourse = async (uid) => {
    try {
      const { data } = await api({
        url: `/courses/${uid}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createCourse = async (course) => {
    try {
      const { data } = await api({
        url: '/courses',
        method: 'post',
        data: course
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateCourse = async (uid, course) => {
    try {
      const { data } = await api({
        url: `/courses/${uid}`,
        method: 'put',
        data: course
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getCourses,
    getCourse,
    createCourse,
    updateCourse
  };
};

export default useCourseApi;
