import AuthPageLayout from './AuthPageLayout';
import { CircularProgress, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link } from 'ui';
import useViewer from '../../components/Viewer/ViewerContext/useViewer';

const UserConfirmPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState(false);
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const { confirmUser } = useViewer();

  useEffect(() => {
    const activateUser = async () => {
      setLoading(true);
      const result = await confirmUser({ code, email });
      setActivated(result);
      setLoading(false);
    };
    if (email && code) {
      activateUser();
    }
  }, [email, code]);

  return (
    <AuthPageLayout title='Email megerősítés'>
      {code && email && (
        <>
          {loading && <CircularProgress />}
          {!loading && activated && (
            <>
              <Typography>
                A fiókodat sikeresen aktiváltuk, most már be tudsz lépni a regisztráció során megadott email címeddel és
                jelszóval!
              </Typography>
              <Typography>
                <Link to='/login'>Vissza a BELÉPÉS oldalra!</Link>
              </Typography>
            </>
          )}
          {!loading && !activated && (
            <Typography>
              A fiók aktiválása nem sikerült, lehetséges, hogy lejárt az aktivációs linked vagy a fiókod már korábban
              aktiváltad.
            </Typography>
          )}
        </>
      )}
      {(!code || !email) && (
        <Typography>
          A regisztráció során megadott email címedre elküldtünk egy megerősítő emailt. Kövesd a levélben leírt
          utasításokat a regisztráció befejezéséhez. {email}
        </Typography>
      )}
    </AuthPageLayout>
  );
};

export default UserConfirmPage;
