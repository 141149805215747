import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormError from './FormError';
import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material';
import throttle from 'lodash/throttle';
import useApi from '../../../api/useApi';

const AutoComplete = ({ name, url, defaultValue, multiple, label, ...props }) => {
  const formContext = useFormContext();
  const labelId = `component-${label}`;
  const { api } = useApi();
  const variant = 'outlined';

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const fetch = React.useMemo(
    () =>
      throttle(async (fetchUrl, searchString, callback) => {
        const response = await api({
          url: `${fetchUrl}=${searchString}`,
          method: 'get'
        });
        callback(response?.data?.result);
      }, 700),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '' || inputValue.length < 3) {
      setOptions([]);
      return undefined;
    }

    setLoading(true);

    fetch(url, inputValue, (result) => {
      setLoading(false);

      if (active) {
        let newOptions = [];

        if (result) {
          newOptions = [...newOptions, ...(result || [])];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch, url]);

  return (
    <Controller
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <FormError error={error}>
          <FormControl fullWidth variant={variant} error={!!error}>
            <Autocomplete
              labelId={labelId}
              // native={!multiple}
              multiple={multiple}
              variant={variant}
              label={label}
              onChange={(event, newValue) => {
                // setOptions(newValue ? [newValue, ...options] : options);
                onChange(newValue);
              }}
              onBlur={onBlur}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              fullWidth
              ref={ref}
              notched
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={options}
              getOptionValueLabel={(option) => option.value || ''}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              {...props}
            />
          </FormControl>
        </FormError>
      )}
      name={name}
      control={formContext.control}
      defaultValue={defaultValue || ''}
    />
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  multiple: PropTypes.bool,
  label: PropTypes.string
};

export default AutoComplete;
