import { Typography } from '@mui/material';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

const HelpDashboard = () => {
  const { viewerRoleIs, viewerRoles, viewer } = useViewer();
  return (
    <div>
      {viewerRoleIs(viewerRoles.ADMIN) && (
        <>
          <Typography variant='h2' gutterBottom>
            Hello {viewer.firstName}! 🙂
          </Typography>
          <Typography variant='body1' gutterBottom>
            Ebben a profilban lehetőséged van koordinálni a nyelviskola működését:
          </Typography>
          <Typography variant='body1' gutterBottom>
            <ul>
              <li>tanfolyamokat létrehozni, szerkeszteni</li>
              <li>felhasználói (tanári, tanulói, admin) profilokat kezelni</li>
              <li>tanulók tanulási folyamatát adminisztrálni</li>
              <li>beiratkoztatást, bérletvásárlást kezelni</li>
              <li>tanárok munkáját adminisztrálni</li>
              <li>számlázási adatokat, cégadatokat szerkeszteni</li>
              <li>stb. 🙂</li>
            </ul>
          </Typography>
          <Typography variant='body1' gutterBottom>
            A tanulók és a kollégáid számítanak rád, mindig légy alapos és precíz!
          </Typography>
          <Typography variant='body1' gutterBottom>
            Jó munkát! 🙂
          </Typography>
        </>
      )}
      {viewerRoleIs(viewerRoles.TEACHER) && (
        <>
          <Typography variant='h2' gutterBottom>
            Hello {viewer.firstName}! 🙂
          </Typography>
          <Typography variant='body1' gutterBottom>
            Szuper, hogy Te is az Up2You csapatát erősíted! 🙂
          </Typography>
          <Typography variant='body1' gutterBottom>
            A profilodban minden szükséges információt megtalálsz a munkádhoz:
          </Typography>
          <Typography variant='body1' gutterBottom>
            <ul>
              <li>a tanulók adatait, elérhetőségét és programját a tanulói adatlapon</li>
              <li>bérletinformációkat és az elérhető tanfolyamokat</li>
              <li>a nyelviskola naptárát: nyitvatartás, munkaszüneti napok</li>
              <li>kollégáid elérhetőségét</li>
            </ul>
          </Typography>
          <Typography variant='body1' gutterBottom>
            A tanulók és a kollégáid számítanak rád, mindig légy alapos és precíz!
          </Typography>
          <Typography variant='body1' gutterBottom>
            Ha segítségre van szükséged, kérd ADMIN segítségét!
          </Typography>
          <Typography variant='body1' gutterBottom>
            Jó munkát és sikerélményekkel teli tanítást kívánunk! 🙂
          </Typography>
        </>
      )}
      {viewerRoleIs(viewerRoles.STUDENT) && (
        <>
          <Typography variant='h2' gutterBottom>
            Hello {viewer.firstName}!
          </Typography>
          <Typography variant='body1' gutterBottom>
            Szeretettel üdvözlünk az Up2You Nyelviskola tanulójaként! 🙂
          </Typography>
          <Typography variant='body1' gutterBottom>
            Örülünk, hogy minket választottál, és elkísérhetünk a célodhoz vezető úton! A profilodban minden fontos
            információ rendelkezésedre áll a tanulásodról: tanfolyamaid, bérleteid, felhasznált óráid, órarended és a
            személyreszabott programod. Van lehetőséged online időpontfoglalásra és új bérlet vásárlására is. Ha
            kapcsolatba szeretnél lépni velünk, írj nekünk emailt a recepcio@up2you.hu címre! Jó tanulást, sok
            sikerélményt kívánunk! 🙂 Kattints az alábbi gombra, és böngéssz a tanfolyamaink között!
          </Typography>
        </>
      )}
    </div>
  );
};

export default HelpDashboard;
