import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ children, ...props }) => {
  return (
    <MuiLink component={RouterLink} {...props}>
      {children}
    </MuiLink>
  );
};

export default Link;
