import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CourseCard from './CourseCard';
import CourseCardLinkToAll from './CourseCardLinkToAll';

const wrapperSx = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: { xs: 'center', md: 'stretch' },
  flexWrap: 'wrap',
  gap: 2
};

const cardSx = {};

const CourseList = ({ courses, loading, linkToAllCourses }) => {
  return (
    <Box sx={wrapperSx}>
      {!loading &&
        courses.map((course) => (
          <CourseCard sx={cardSx} key={course.uid} item={course} itemLink={`/courses/${course.uid}`} />
        ))}
      {!loading && linkToAllCourses && <CourseCardLinkToAll />}
    </Box>
  );
};

CourseList.propTypes = {
  courses: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  linkToAllCourses: PropTypes.bool
};

export default CourseList;
