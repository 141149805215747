import { styled, Typography } from '@mui/material';

const Row = styled('div')(({ theme }) => ({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.grey[100],
  padding: theme.spacing(0.75, 1),
  [theme.breakpoints.up('sm')]: {
    display: 'flex'
  },
  '&:last-child': {
    border: 'none'
  }
}));

const Label = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.primary
}));

Label.defaultProps = {
  variant: 'h6'
};

const Name = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 300,
  verticalAlign: 'baseline',
  textAlign: 'left',
  [theme.breakpoints.up('sm')]: {
    flexBasis: 1,
    flexShrink: 0,
    flexGrow: 1,
    width: '40%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '40%',
    minWidth: '40%'
  }
}));

Name.defaultProps = {
  variant: 'body2'
};

const Value = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'right'
  }
}));

Value.defaultProps = {
  variant: 'body1'
};

export { Row, Name, Value, Label };
