import { Box } from '@mui/material';

const Toolbar = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 2,
        mx: { xs: -1, md: -4 },
        mt: { xs: -1, md: -4 },
        mb: { xs: 1, md: 3 },
        bgcolor: '#ffffff80',
        borderColor: 'divider',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        px: { xs: 1, md: 3 },
        py: { xs: 1, md: 1 }
      }}
    >
      {children}
    </Box>
  );
};

export default Toolbar;
