import * as yup from 'yup';

const useStudentCoursesFields = () => {
  return [
    {
      name: 'name',
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'weight',
      type: 'number',
      cellRenderer: (row) => row.weight || 1,
      showInTable: true,
      fieldProps: {
        label: 'Súly',
        step: '0.1'
      },
      rule: yup.number()
    }
  ];
};

export default useStudentCoursesFields;
