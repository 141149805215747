import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useSearchParams } from 'react-router-dom';

const useLanguagesFilters = () => {
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: [
        { key: 'active', value: 'Aktív' },
        { key: 'inactive', value: 'Inaktív' }
      ],
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useLanguagesFilters;
