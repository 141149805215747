import React from 'react';
import { Grid, Stack, Box, Typography } from '@mui/material';
import { getFieldComponent, getYupSchema } from './FormUtils';
import Button from '../Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ConfirmButton from 'ui/ConfirmButton/ConfirmButton';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import useTranslation from 'hooks/useTranslation';

// const useStyles = makeStyles(theme => ({
//     actions: {
//         textAlign: 'right',
//         '& > *': {
//             marginLeft: theme.spacing(3),
//         },
//     },
// }));

const Form = ({
  fields,
  onSubmit,
  message,
  onAltAction,
  onExtraAction,
  extraActionLabel,
  submitLabel,
  noValidate,
  loading,
  defaultValues,
  readOnly,
  resetAfterSubmit,
  fullWidthSubmit,
  sx,
  inline,
  gap
}) => {
  const schema = noValidate ? yup.mixed() : getYupSchema(fields);
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  const onFormSubmission = (fields) => {
    onSubmit(fields);
    if (resetAfterSubmit) {
      reset();
    }
  };

  return (
    <Box sx={{ mt: 2, ...sx }}>
      {message && (
        <Typography variant='body1' sx={{ mt: 0, mb: 4 }}>
          {message}
        </Typography>
      )}
      <FormProvider {...methods}>
        <form noValidate={noValidate} onSubmit={handleSubmit(onFormSubmission)}>
          <Grid container spacing={gap || 3}>
            {(fields || []).map((field) => (
              <React.Fragment key={field.name}>
                {field.type ? (
                  <Grid item xs={inline ? 6 : 12}>
                    {getFieldComponent(field, loading, defaultValues ? defaultValues[field.name] || '' : '', readOnly)}
                  </Grid>
                ) : null}
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <Stack gap={2} direction='row' justifyContent={'space-between'}>
                {onExtraAction && extraActionLabel && (
                  <Box sx={{ flexGrow: 1 }}>
                    <ConfirmButton
                      disabled={loading}
                      color='error'
                      variant='text'
                      onConfirm={onExtraAction}
                      title={extraActionLabel}
                      startIcon={<DeleteTwoToneIcon />}
                    />
                  </Box>
                )}

                <Stack spacing={2} direction='row' justifyContent={'flex-end'} sx={{ flexGrow: 1 }}>
                  {onAltAction && (
                    <Button disabled={loading} color='secondary' variant='text' onClick={onAltAction}>
                      {t('common.cancel')}
                    </Button>
                  )}
                  {onSubmit && (
                    <Button
                      disabled={
                        loading || (!methods.formState.isValid && Object.keys(methods.formState.errors).length > 0)
                      }
                      loading={loading}
                      type='submit'
                      fullWidth={fullWidthSubmit}
                    >
                      {submitLabel || 'Mentés'}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Form;
