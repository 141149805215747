import * as yup from 'yup';
import useOptions from 'components/Options/OptionsContext/useOptions';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

export const useStudentInvoiceFieldsPerson = () => {
  return [
    {
      name: 'isCompany',
      cellRenderer: (row) => (row.isCompany === '1' ? 'Cég' : 'Magánszemély'),
      showInTable: true,
      fieldProps: {
        label: 'Típus'
      }
    },
    {
      name: 'invoiceName',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Számlázási név'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCountry',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Ország'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCity',
      showInTable: true,
      type: 'text',
      fieldProps: {
        label: 'Város'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceAddress',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Cím'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceZip',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Irányítószám'
      },
      rule: yup.string().required()
    }
  ];
};

export const useStudentInvoiceFieldsCompany = () => {
  return [
    {
      name: 'isCompany',
      cellRenderer: (row) => (row.isCompany === '1' ? 'Cég' : 'Magánszemély'),
      showInTable: true,
      fieldProps: {
        label: 'Típus'
      }
    },
    {
      name: 'invoiceName',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Cégnév'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCountry',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Ország'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCity',
      showInTable: true,
      type: 'text',
      fieldProps: {
        label: 'Város'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceAddress',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Cím'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceZip',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Irányítószám'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceTaxNumber',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Adószám'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceEUTaxNumber',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'EU Adószám'
      },
      rule: yup.string()
    }
  ];
};

export const useUserDetailsFields = () => {
  const { paymentProfiles } = useOptions();
  const { viewerHasRole, viewerRoles } = useViewer();

  return [
    {
      name: 'name',
      cellRenderer: (row) => `${row.lastName || ''} ${row.firstName || ''}`,
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'lastName',
      type: 'text',
      fieldProps: {
        label: 'Vezetéknév'
      },
      rule: yup.string().required()
    },
    {
      name: 'firstName',
      type: 'text',
      fieldProps: {
        label: 'Keresztnév'
      },
      rule: yup.string().required()
    },
    {
      name: 'email',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Email'
      },
      rule: yup.string().email().required()
    },
    {
      name: 'phone',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Telefon'
      },
      rule: yup.string().required()
    },
    ...(viewerHasRole(viewerRoles.ADMIN)
      ? [
          {
            name: 'paymentProfile',
            type: 'select',
            showInTable: true,
            cellRenderer: (row) => paymentProfiles.find((item) => item.key === row.paymentProfile)?.value || '',
            fieldProps: {
              label: 'Számlázási információ',
              options: paymentProfiles
            },
            rule: yup.string()
          }
        ]
      : [])
  ];
};
