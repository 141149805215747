export const DATE_FORMATS = {
  dateTime: { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' },
  longDate: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
  shortDate: { year: 'numeric', month: 'numeric', day: 'numeric' },
  day: { day: '2-digit' },
  month: { month: 'short' },
  time: { hour: '2-digit', minute: '2-digit' }
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const formatDate = (timestamp, format = DATE_FORMATS.dateTime) => {
  const date = new Date(timestamp.length === 10 ? timestamp * 1000 : timestamp);
  return date.toLocaleString('hu-HU', format);
};

export const isToday = (timestamp) => {
  const d = new Date(timestamp * 1000);
  const today = new Date();
  return (
    d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear()
  );
};

export const localIsoStringToUtcTimestamp = (localIsoString) => {
  const localDate = new Date(localIsoString); // Parse the local ISO string to a Date object
  if (!isValidDate(localDate)) {
    return localIsoString;
  }
  const utcTimestamp = localDate.getTime() / 1000; // Get the UTC timestamp in seconds
  return utcTimestamp;
};

export const utcTimestampToLocalIsoString = (utcTimestamp) => {
  const utcDate = new Date(utcTimestamp * 1000); // Create a Date object using the UTC timestamp
  if (!isValidDate(utcDate)) {
    return '';
  }
  const localIsoString = utcDate.toISOString(); // Convert UTC date to local ISO string
  return localIsoString;
};

export const getHourAndMinutesFromMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (mins === 0) {
    return `${hours} óra`;
  }
  if (hours === 0) {
    return `${mins} perc`;
  }
  return `${hours} óra ${mins} perc`;
};

export const getDayInUnixTimestamp = (date) => {
  var startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  console.log(startOfDay.getTime());
  return startOfDay.getTime() / 1000;
};
