import React, { useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FormGroup, IconButton, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import CheckboxFilter from './CheckboxFilter';
import InputFilter from './InputFilter';
import SelectFilter from './SelectFilter';
import DateFilter from './DateFilter';

export const FilterType = {
  Checkbox: 'checkbox',
  Select: 'select',
  MultiSelect: 'multiselect',
  Search: 'search',
  Date: 'date'
};

export const getInitFilterValue = (type) => {
  const initFilterValue = {
    [FilterType.Checkbox]: false,
    [FilterType.Select]: '',
    [FilterType.MultiSelect]: [],
    [FilterType.Search]: '',
    [FilterType.Date]: ''
  };
  return initFilterValue[type];
};

const TableFilter = ({ title, initialFilters, onFilterChange, sx }) => {
  const [selectedFilters, setSelectedFilters] = useState(
    initialFilters.map((filter) => ({ ...filter, filterValue: filter.filterInitialValue || filter.filterValue }))
  );

  const setFilters = (newFilters) => {
    setSelectedFilters(newFilters);

    if (typeof onFilterChange === 'function') {
      onFilterChange(newFilters);
    }
  };

  const handleFilterChange = (id, filterValue) => {
    const newState = selectedFilters.map((filter) => {
      if (filter.id !== id) {
        return filter;
      }

      return {
        ...filter,
        filterValue
      };
    });

    setFilters(newState);
  };

  const handleFiltersChange = (id, value) => {
    const newState = selectedFilters.map((filter) => {
      if (filter.id !== id) {
        return filter;
      }

      const filterValue = filter.filterValue;
      const newFilterValue = filterValue.includes(value)
        ? filterValue.filter((val) => val !== value)
        : [...filterValue, value];

      return {
        ...filter,
        filterValue: newFilterValue
      };
    });

    setFilters(newState);
  };

  const handleFilterClear = () => {
    setFilters(
      initialFilters.map((filter) => ({ ...filter, filterValue: filter.filterInitialValue || filter.filterValue }))
    );
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: { xs: 1, md: 3 },
        py: { xs: 0, md: 0 },
        mx: { xs: -1, md: -3 },
        mb: 1,
        border: 0,
        background: 'transparent',
        overflowX: 'auto',
        ...sx
      }}
      elevation={0}
      square
      variant='outlined'
    >
      <Box sx={{ flexGrow: 1 }}>
        <FormGroup>
          <Stack direction='row' alignItems='center' spacing={1} sx={{ alignItems: 'center' }}>
            {title && <Typography sx={{ mr: 2, color: 'text.secondary' }}>{title}</Typography>}
            {selectedFilters.map(({ filterValue, filterType, filterOptions, label, id, icon }) => {
              if (filterType === FilterType.MultiSelect && filterOptions && Array.isArray(filterValue)) {
                return (
                  <SelectFilter
                    key={id}
                    id={id}
                    label={label}
                    filterOptions={filterOptions}
                    filterValue={filterValue}
                    handleMultiSelectChange={handleFiltersChange}
                    icon={icon}
                  />
                );
              }

              if (filterType === FilterType.Select && filterOptions) {
                return (
                  <SelectFilter
                    key={id}
                    id={id}
                    label={label}
                    filterValue={filterValue}
                    filterOptions={filterOptions}
                    handleMultiSelectChange={handleFilterChange}
                    icon={icon}
                  />
                );
              }

              if (filterType === FilterType.Checkbox) {
                return (
                  <CheckboxFilter
                    id={id}
                    key={id}
                    label={label}
                    checked={filterValue}
                    handleCheckboxChange={handleFilterChange}
                  />
                );
              }

              if (filterType === FilterType.Date) {
                return (
                  <DateFilter
                    id={id}
                    key={id}
                    label={label}
                    filterValue={filterValue}
                    handleFilterChange={handleFilterChange}
                  />
                );
              }

              return null;
            })}
            <IconButton onClick={handleFilterClear}>
              <CloseRoundedIcon sx={{ color: 'text.secondary' }} />
            </IconButton>
          </Stack>
        </FormGroup>
      </Box>
      <Box>
        {selectedFilters
          .filter(({ filterType }) => filterType === FilterType.Search)
          .map(({ filterValue, label, id }) => (
            <InputFilter
              key={id}
              id={id}
              placeholder={label}
              filterValue={filterValue}
              handleFilterChange={handleFilterChange}
            />
          ))}
      </Box>
    </Paper>
  );
};

export default TableFilter;
