import React from 'react';
import { Paper as MuiPaper } from '@mui/material';

const Paper = ({ children, backgroundImageUrl, minHeight, variant, padding, sx, ...paperProps }) => {
  const paperSx = {
    padding: padding === undefined ? 2 : padding,
    backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none',
    backgroundSize: 'cover',
    minHeight: `${minHeight}px`,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: 'divider'
  };

  return (
    <MuiPaper sx={{ ...paperSx, ...sx }} variant={variant || 'outlined'} {...paperProps}>
      {children}
    </MuiPaper>
  );
};

export default Paper;
