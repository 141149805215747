import hu from 'languages/hu.json';

const languages = { hu };

const useTranslation = (language = 'hu') => {
  const t = (reference) => {
    const getSegment = (segments, langObject) => {
      const s = segments.split('.');
      const result = langObject[s[0]];

      if (result && typeof result === 'object' && result.constructor === Object) {
        return getSegment(s.slice(1).join('.'), result);
      } else {
        return result || `< missing translation ${language}:${reference} >`;
      }
    };

    return getSegment(reference, languages[language]);
  };

  return { t };
};

export default useTranslation;
