import React, { Fragment, useState } from 'react';

import EditRoundedIcon from '@mui/icons-material/EditRounded';

import Paper from 'ui/Paper/Paper';
import Dialog from 'ui/Dialog/Dialog';
import Form from 'ui/Form/Form';
import Title from 'ui/Title/Title';

import { Label, Name, Row, Value } from './detailsStyles';
import { Box, IconButton, Skeleton } from '@mui/material';

const Details = ({ title, fields, data, onUpdate, updateMessage, updateTitle, loading, justifyBetween }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async (formFields) => {
    setFormLoading(true);
    if (onUpdate) {
      await onUpdate(formFields);
    }
    setFormLoading(false);
    setDialogOpen(false);
  };

  return (
    <Box>
      {title && (
        <Title variant='body1' title={title} gutterBottom={1}>
          {onUpdate && (
            <IconButton onClick={() => setDialogOpen(true)} disabled={loading} size='small'>
              <EditRoundedIcon fontSize='small' />
            </IconButton>
          )}
        </Title>
      )}
      {loading && <Skeleton variant='rectangular' height={200} />}
      {!loading && (
        <Paper padding={0}>
          {fields.map((field, index) => (
            <Fragment key={`${field.displaytype}-${index}`}>
              {field.displaytype === 'label' ? (
                <Row>
                  <Label>{field.label}</Label>
                </Row>
              ) : (
                <>
                  {field.showInTable ? (
                    <Row sx={justifyBetween ? { justifyContent: 'space-between' } : {}}>
                      <Name variant='body2'>{field.fieldProps.label}</Name>
                      <Value>{field.cellRenderer ? field.cellRenderer(data) : data[field.name]}</Value>
                    </Row>
                  ) : null}
                </>
              )}
            </Fragment>
          ))}
        </Paper>
      )}

      <Dialog title={updateTitle || title} isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} hideActions>
        <Form
          fields={fields}
          onSubmit={onUpdate ? handleSubmit : null}
          onAltAction={handleCloseDialog}
          submitLabel={updateTitle}
          loading={isFormLoading}
          defaultValues={data}
          message={updateMessage}
        />
      </Dialog>
    </Box>
  );
};

export default Details;
