import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

const Spinner = ({ size = 40, sx = {} }) => {
  return (
    <Box sx={{ position: 'relative', width: size, mx: 'auto', ...sx }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          animationDuration: '1550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={size}
        thickness={4}
      />
    </Box>
  );
};

export default Spinner;
