import PropTypes from 'prop-types';
import { Stack, styled, Typography } from '@mui/material';
import inProgressImg from './in-progress.svg';
import emptyImg from './empty.svg';
import successImg from './success.svg';
import { Button } from 'ui';
import { Link } from 'react-router-dom';

const Image = styled('img')({
  maxWidth: '16rem',
  width: '100%'
});

const images = {
  inProgress: inProgressImg,
  empty: emptyImg,
  success: successImg
};

const Illustration = ({ type, title, description, link, linkText, sx }) => {
  return (
    <Stack justifyContent='center' alignItems='center' sx={sx}>
      <Image src={images[type]} alt='in progress' />
      {title && (
        <Typography variant='h5' sx={{ mt: 6 }}>
          {title}
        </Typography>
      )}
      {description && <Typography>{description}</Typography>}
      {link && linkText && (
        <Button component={Link} to={link} sx={{ mt: 4 }} size='large'>
          {linkText}
        </Button>
      )}
    </Stack>
  );
};

Illustration.defaultProps = {
  sx: {}
};

Illustration.propTypes = {
  type: PropTypes.oneOf(['inProgress', 'empty']).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  sx: PropTypes.object
};

export default Illustration;
