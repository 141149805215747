import * as yup from 'yup';

const loginFormFields = [
  {
    name: 'email',
    type: 'text',
    fieldProps: {
      label: 'Email',
      autoFocus: true
    },
    rule: yup.string().email().required('Add meg a regisztrált email címed')
  },
  {
    name: 'password',
    type: 'password',
    fieldProps: {
      label: 'Jelszó'
    },
    rule: yup.string().required('Add meg a jelszavad')
  }
  // {
  //   name: 'remember',
  //   type: 'checkbox',
  //   fieldProps: {
  //     label: 'Emlékezz rám'
  //   },
  //   rule: yup.boolean()
  // }
];

export default loginFormFields;
