import { useEffect, useState } from 'react';
import useStudentApi from './useStudentApi';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import { Typography } from '@mui/material';
import Spinner from '../../ui/Spinner/Spinner';

const StudentTransferInformation = ({ studentId }) => {
  const [isLoading, setLoading] = useState(false);
  const [paymentProfile, setPaymentProfile] = useState([]);
  const { getStudentProfile } = useStudentApi();

  const fetchStudentProfile = async (id) => {
    setLoading(true);
    const response = await getStudentProfile(id);
    setPaymentProfile(response.paymentProfile);
    setLoading(false);
  };

  useEffect(() => {
    fetchStudentProfile(studentId);
  }, [studentId]);

  return (
    <>
      <ShoppingCartTwoToneIcon sx={{ fontSize: '4rem', color: 'primary.main' }} />
      <Typography variant='h5' sx={{ mt: 1, mb: 1, textAlign: 'center' }}>
        Bérlet vásárlás átutalással
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'center' }}>
        A bérlet megrendelésekor átutalásos fizetést választottál, kérjük a bérlet árát az alábbi számlaszámra utald át.
        Az átutaláskor közleményben mindenképen tüntesd fel a megrendelés számát. A bérletet a befizetés beérkezését
        követően aktiváljuk.
      </Typography>
      {isLoading && (
        <>
          <Spinner />
          <Typography variant='h5' sx={{ mt: 1, mb: 1, textAlign: 'center' }}>
            Tranzakció előkészítése
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center' }}>
            Egy kis türlemet kérünk amig előkészítjük a tranzakciót.
          </Typography>
        </>
      )}
      {!isLoading && paymentProfile && (
        <>
          <Typography variant='body1' sx={{ textAlign: 'center', mt: 2 }}>
            <strong>{paymentProfile.name}</strong>
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center' }}>
            <strong>{paymentProfile.account_number}</strong>
          </Typography>
        </>
      )}
    </>
  );
};

export default StudentTransferInformation;
