import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { localIsoStringToUtcTimestamp, utcTimestampToLocalIsoString } from 'utils/date';
import { TextField } from '@mui/material';

const DateTimeInput = ({ name, defaultValue, loading, disabled, ...dateFieldProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        const handleChange = (newValue) => {
          console.log({ newValue, timestamp: localIsoStringToUtcTimestamp(newValue) });
          onChange(localIsoStringToUtcTimestamp(newValue));
        };
        return (
          <DateTimePicker
            renderInput={(params) => <TextField fullWidth {...params} />}
            variant='outlined'
            name={name}
            error={!!error}
            onChange={handleChange}
            onBlur={onBlur}
            value={utcTimestampToLocalIsoString(value)}
            inputRef={ref}
            helperText={error?.message}
            disabled={disabled || loading}
            format='yyyy.MM.dd HH:mm'
            ampm={false}
            InputLabelProps={{ shrink: true }}
            {...dateFieldProps}
          />
        );
      }}
    />
  );
};

DateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default DateTimeInput;
