import React, { useContext, useState } from 'react';
import { Dialog } from 'ui';

const DialogContext = React.createContext({});

const DialogContextProvider = ({ children }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState({});

  const openDialog = (options) => {
    setDialogOptions(options);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    if (dialogOptions.onClose) dialogOptions.onClose();
    setDialogOpen(false);
  };

  return (
    <DialogContext.Provider value={{ isDialogOpen, setDialogOpen, openDialog, closeDialog }}>
      {children}
      <Dialog isOpen={isDialogOpen} onClose={closeDialog} {...dialogOptions} />
    </DialogContext.Provider>
  );
};

const useDialog = () => useContext(DialogContext);

export { DialogContextProvider, useDialog };
