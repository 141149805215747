import React from 'react';
import PropTypes from 'prop-types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const InputFilter = ({ handleFilterChange, id, filterValue, placeholder }) => {
  // TODO debounce
  const handleChange = (event) => {
    handleFilterChange(id, event.target.value);
  };

  return (
    <TextField
      id={id}
      size='small'
      value={filterValue}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchRoundedIcon color='secondary' />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {filterValue.length > 0 && (
              <InputAdornment position='end'>
                <IconButton onClick={() => handleFilterChange(id, '')} edge='end'>
                  <CloseRoundedIcon color='secondary' />
                </IconButton>
              </InputAdornment>
            )}
          </>
        )
      }}
    />
  );
};

InputFilter.propTypes = {
  id: PropTypes.string,
  filterValue: PropTypes.string,
  placeholder: PropTypes.string,
  handleFilterChange: PropTypes.func
};

export default InputFilter;
