import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import RequireAuth from 'components/Routes/RequireAuth';
import ProtectedRoutes from 'components/Routes/ProtectedRoutes';
import NotFoundPage from 'pages/NotFoundPage';
import InnerLayout from 'components/Layout/InnerLayout';

import config from 'config';

const Routes = () => {
  return (
    <RouterRoutes>
      <Route
        path='/'
        element={
          <RequireAuth>
            <ProtectedRoutes />
          </RequireAuth>
        }
      >
        {config.routes.map((route) =>
          route.protected ? (
            <Route
              key={route.path}
              path={route.path}
              element={<InnerLayout route={route}>{route.element}</InnerLayout>}
            />
          ) : null
        )}
      </Route>

      {config.routes.map((route) =>
        !route.protected ? <Route key={route.path} path={route.path} element={route.element} /> : null
      )}

      <Route exact path='*' element={<NotFoundPage />} />
    </RouterRoutes>
  );
};

export default Routes;
