import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { getDayInUnixTimestamp } from 'utils/date';
import { Button, InputBase } from '@mui/material';
import EventRoundedIcon from '@mui/icons-material/EventRounded';

const DateFilter = ({ handleFilterChange, id, filterValue, placeholder }) => {
  const handleChange = (date) => {
    handleFilterChange(id, getDayInUnixTimestamp(new Date(date)));
  };

  return (
    <DatePicker
      id={id}
      size='small'
      value={new Date(filterValue * 1000)}
      onChange={handleChange}
      placeholder={placeholder}
      InputAdornmentProps={{
        color: 'secondary',
        position: 'start',
        children: <EventRoundedIcon />,
        disableFocusRipple: true,
        disableRipple: true
      }}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <Button color='secondary' variant='text' sx={{ width: '10rem', pl: 1 }} disableRipple>
          {InputProps?.startAdornment}
          <InputBase
            ref={inputRef}
            {...inputProps}
            sx={{ fontWeight: 700, color: (theme) => theme.palette.secondary.main, fontSize: '0.875rem' }}
          />
        </Button>
      )}
    />
  );
};

DateFilter.propTypes = {
  id: PropTypes.string,
  filterValue: PropTypes.string,
  placeholder: PropTypes.string,
  handleFilterChange: PropTypes.func
};

export default DateFilter;
