import { Tiles } from 'ui';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import { useMemo } from 'react';
import useTranslation from 'hooks/useTranslation';

const MyProfilePage = () => {
  const { t } = useTranslation();
  const { viewerRoleIs, viewerRoles } = useViewer();

  const viewerTiles = useMemo(
    () => [
      {
        title: t('profile.details'),
        icon: <AccountCircleTwoToneIcon />,
        path: '/my-profile/details'
      },
      ...((viewerRoleIs(viewerRoles.STUDENT) && []) || [])
    ],
    []
  );

  return <Tiles tiles={viewerTiles} sx={{ mb: 2 }} />;
};

export default MyProfilePage;
