import { styled } from '@mui/system';

export const CalendarContainer = styled('div')(({ theme }) => ({
  height: 'calc(100dvh - 300px)',
  // '--fc-small-font-size': '.85em',
  // '--fc-page-bg-color': '#fff',
  // '--fc-neutral-bg-color': 'rgba(208, 208, 208, 0.3)',
  // '--fc-neutral-text-color': '#808080',
  '--fc-border-color': theme.palette.divider,

  '--fc-button-text-color': theme.palette.text.contrastText,
  '--fc-button-bg-color': theme.palette.primary.main,
  '--fc-button-border-color': theme.palette.primary.main,
  '--fc-button-hover-bg-color': theme.palette.primary.dark,
  '--fc-button-hover-border-color': theme.palette.primary.dark,
  '--fc-button-active-bg-color': theme.palette.primary.main,
  '--fc-button-active-border-color': theme.palette.primary.dark,

  '--fc-event-bg-color': theme.palette.primary.light,
  '--fc-event-border-color': theme.palette.primary.main,
  '--fc-event-text-color': theme.palette.text.primary,
  // '--fc-event-selected-overlay-color': 'rgba(0, 0, 0, 0.25)',

  // '--fc-more-link-bg-color': '#d0d0d0',
  // '--fc-more-link-text-color': 'inherit',

  // '--fc-event-resizer-thickness': '8px',
  // '--fc-event-resizer-dot-total-width': '8px',
  // '--fc-event-resizer-dot-border-width': '1px',

  // '--fc-non-business-color': 'rgba(215, 215, 215, 0.3)',
  '--fc-bg-event-color': 'rgba(143, 223, 130, 0.3)',
  '--fc-bg-event-opacity': '0.5',
  // '--fc-highlight-color': 'rgba(188, 232, 241, 0.3)',
  '--fc-today-bg-color': 'rgba(255, 220, 40, 0.05)',
  '--fc-now-indicator-color': theme.palette.primary.main,

  '.fc-timegrid > table': {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden'
  },
  '.fc .fc-button, .fc .fc-button-primary:not(:disabled), .fc .fc-button-group .fc-button': {
    borderRadius: theme.shape.borderRadius,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '0.875rem',
    transition: 'background-color 0.2s ease',
    border: 'none',
    padding: '0.5rem 1rem',
    '&:focus, &:active:focus': {
      outline: 'none',
      boxShadow: 'none'
    }
  },
  '.fc-bg-event': {
    borderTopColor: 'rgba(143, 223, 130, 0.7)',
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    borderLeftColor: 'rgba(143, 223, 130, 0.7)',
    borderLeftWidth: 2,
    borderLeftStyle: 'solid',
    color: '#266a1c'
  },
  '.fc-timegrid-event': {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.divider,
    borderTopWidth: 4,
    borderTopColor: theme.palette.primary.main,
    transition: 'border-color 0.2s ease',
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
    overflow: 'hidden',
    '.fc-event-main': {
      padding: '2px 4px'
    },
    '.fc-event-title': {
      fontWeight: 'bold'
    }
  },
  '.fc .fc-toolbar-title': {
    fontSize: '1.2rem'
  },
  '.fc .fc-toolbar.fc-header-toolbar': {
    marginBottom: '1rem',
    justifyContent: 'space-between',
    gap: '1rem'
  },
  '.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem'
  }
}));
