import LoginPage from 'pages/auth/LoginPage';
import PasswordResetPage from 'pages/auth/PasswordResetPage';
import DashboardPage from 'pages/DashboardPage';

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

import CoursesPage from './pages/courses/CoursesPage';
import SignUpPage from './pages/auth/SignUpPage';
import UserConfirmPage from './pages/auth/UserConfirmPage';
import CoursePage from './pages/courses/CoursePage';
import LanguagesPage from './pages/admin/LanguagesPage';
import UsersPage from './pages/admin/UsersPage';
import SubscriptionsPage from './pages/admin/SubscriptionsPage';
import AdminPage from './pages/admin/AdminPage';
import PaymentProfilePage from './pages/admin/PaymentProfilePage';
import StudentsPage from './pages/students/StudentsPage';
import StudentProfilePage from './pages/students/StudentProfilePage';
import TeachersPage from './pages/teachers/TeachersPage';
import TeacherProfilePage from './pages/teachers/TeacherProfilePage';
import MyProfilePage from './pages/viewer/MyProfilePage';
import MyTransactionsPage from 'pages/viewer/MyTransactionsPage';
import MyDetailsPage from './pages/viewer/MyDetailsPage';
import PaymentConfirmationPage from './pages/payment/PaymentConfirmationPage';
import CartPage from 'pages/cart/CartPage';
import HelpDashboard from 'components/Help/HelpDashboard';

const config = {
  routes: [
    {
      path: '/',
      element: <DashboardPage />,
      protected: true,
      title: 'Dashboard',
      breadcrumbs: [],
      helpComponent: <HelpDashboard />
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/reset-password',
      element: <PasswordResetPage />
    },
    {
      path: '/user-confirm',
      element: <UserConfirmPage />
    },
    {
      path: '/signup',
      element: <SignUpPage />
    },
    {
      path: '/my-profile',
      element: <MyProfilePage />,
      protected: true,
      title: 'Profilom',
      breadcrumbs: []
    },
    {
      path: '/my-profile/details',
      element: <MyDetailsPage />,
      protected: true,
      title: 'Adataim',
      breadcrumbs: [{ title: 'Profilom', path: '/my-profile' }]
    },
    {
      path: '/my-profile/transactions',
      element: <MyTransactionsPage />,
      protected: true,
      title: 'Tranzakcióim',
      breadcrumbs: [{ title: 'Profilom', path: '/my-profile' }]
    },
    {
      path: '/students',
      element: <StudentsPage />,
      protected: true,
      title: 'Tanulók',
      breadcrumbs: []
    },
    {
      path: '/students/:id',
      element: <StudentProfilePage />,
      protected: true,
      title: 'Tanuló profil',
      breadcrumbs: [{ title: 'Tanulók', path: '/students' }]
    },
    {
      path: '/teachers',
      element: <TeachersPage />,
      protected: true,
      title: 'Tanárok',
      breadcrumbs: []
    },
    {
      path: '/teachers/:id',
      element: <TeacherProfilePage />,
      protected: true,
      title: 'Tanár profil',
      breadcrumbs: [{ title: 'Tanárok', path: '/teachers' }]
    },
    {
      path: '/courses',
      element: <CoursesPage />,
      protected: true,
      title: 'Tanfolyamok',
      breadcrumbs: []
    },
    {
      path: '/courses/:id',
      element: <CoursePage />,
      protected: true,
      title: 'Tanfolyam részletek',
      breadcrumbs: [{ title: 'Tanfolyamok', path: '/courses' }]
    },
    {
      path: '/cart/:id',
      element: <CartPage />,
      protected: true,
      title: 'Kosár',
      breadcrumbs: []
    },
    {
      path: '/payment',
      element: <PaymentConfirmationPage />,
      protected: true,
      title: 'Fizetés',
      breadcrumbs: []
    },
    {
      path: '/admin',
      element: <AdminPage />,
      protected: true,
      title: 'Admin',
      breadcrumbs: []
    },
    {
      path: '/admin/languages',
      element: <LanguagesPage />,
      protected: true,
      title: 'Nyelvek',
      breadcrumbs: []
    },
    {
      path: '/admin/payment-profiles',
      element: <PaymentProfilePage />,
      protected: true,
      title: 'Cégek',
      breadcrumbs: []
    },
    {
      path: '/admin/subscriptions',
      element: <SubscriptionsPage />,
      protected: true,
      title: 'Bérletek',
      breadcrumbs: []
    },
    {
      path: '/admin/users',
      element: <UsersPage />,
      protected: true,
      title: 'Felhasználók',
      breadcrumbs: []
    }
  ],
  navigation: [
    {
      title: 'dashboard.title',
      id: 'dashboard',
      path: '/',
      icon: <GridViewOutlinedIcon />
    },
    {
      title: 'students.title',
      id: 'students',
      path: '/students',
      icon: <SchoolOutlinedIcon />,
      role: 2
    },
    {
      title: 'teachers.title',
      id: 'teachers',
      path: '/teachers',
      icon: <Person2OutlinedIcon />,
      role: 2
    },
    {
      title: 'courses.title',
      id: 'courses',
      path: '/courses',
      icon: <BookOutlinedIcon />
    },
    {
      title: 'admin.title',
      id: 'admin',
      icon: <ShieldOutlinedIcon />,
      path: '/admin',
      role: 9,
      items: [
        {
          title: 'admin.languages.title',
          id: 'admin-languages',
          path: '/admin/languages',
          icon: <LanguageOutlinedIcon />
        },
        {
          title: 'admin.paymentProfiles.title',
          id: 'admin-paymentProfiles',
          path: '/admin/payment-profiles',
          icon: <StoreOutlinedIcon />
        },
        {
          title: 'admin.subscriptions.title',
          id: 'admin-subscriptions',
          path: '/admin/subscriptions',
          icon: <ConfirmationNumberOutlinedIcon />
        },
        {
          title: 'admin.users.title',
          id: 'admin-users',
          path: '/admin/users',
          icon: <PeopleOutlineOutlinedIcon />
        }
      ]
    }
  ]
};

export default config;
