import { useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import { Box, Stack } from '@mui/material';
import { formatDate, DATE_FORMATS } from 'utils/date';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Button } from 'ui';
import ConfirmButton from 'ui/ConfirmButton/ConfirmButton';

const EntryDetails = ({ selectedEntry, onClose, onDelete }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleEntryDelete = async () => {
    setLoading(true);
    await onDelete(selectedEntry.event.id);
    onClose();
    setLoading(false);
  };

  if (!selectedEntry) return null;

  return (
    <Box sx={{ minWidth: '24rem' }}>
      <Stack alignItems='center' gap={1} direction='row' sx={{ mb: 2 }}>
        <ScheduleRoundedIcon fontSize='small' />
        {formatDate(selectedEntry.event.startStr, DATE_FORMATS.longDate)} /{' '}
        {formatDate(selectedEntry.event.startStr, DATE_FORMATS.time)} -{' '}
        {formatDate(selectedEntry.event.endStr, DATE_FORMATS.time)}
      </Stack>
      <Stack justifyContent='space-between' direction='row' gap={2}>
        <ConfirmButton
          onConfirm={handleEntryDelete}
          startIcon={<DeleteTwoToneIcon />}
          color='error'
          variant='text'
          loading={loading}
          title={t('courses.cancel-entry')}
        />
        <Button onClick={onClose} disabled={loading} variant='text' color='secondary'>
          {t('common.cancel')}
        </Button>
      </Stack>
    </Box>
  );
};

export default EntryDetails;
